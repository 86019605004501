import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';
import { PMREMGenerator } from 'three';
import './AnatomyThreeScene.css';

const AnatomyThreeScene = () => {
  const mountRef = useRef(null);
  const [hoveredPart, setHoveredPart] = useState('');
  const [currentModel, setCurrentModel] = useState('CNS2.glb');
  const [scale, setScale] = useState(0.05);

  useEffect(() => {
    let currentScene = null;
    let frameId;

    // Set up the main scene
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.01, 2000);
    camera.position.set(0, 0.11, 0.1); // Adjusted for better initial view
    scene.background = new THREE.Color("#191919");

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 0.5; // Increased exposure for better lighting
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;  // Enable soft shadows
    mountRef.current.innerHTML = '';  // Clear previous scene
    mountRef.current.appendChild(renderer.domElement);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;

    // Use RoomEnvironment and PMREMGenerator for realistic lighting
    const pmremGenerator = new PMREMGenerator(renderer);
    const environment = new RoomEnvironment(pmremGenerator);
    scene.environment = pmremGenerator.fromScene(environment).texture;

    const addLightsBasedOnObject = (scene, object) => {
      const box = new THREE.Box3().setFromObject(object);
      const objectCenter = box.getCenter(new THREE.Vector3());
      const objectSize = box.getSize(new THREE.Vector3());
      const lightIntensity = 3;
      const lightDistance = objectSize.length() * 1.5;

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);

      const directionalLight1 = new THREE.DirectionalLight(0xffffff, lightIntensity);
      directionalLight1.position.set(objectCenter.x + lightDistance, objectCenter.y + lightDistance, objectCenter.z);
      directionalLight1.castShadow = true;
      directionalLight1.shadow.mapSize.width = 2048;
      directionalLight1.shadow.mapSize.height = 2048;
      scene.add(directionalLight1);

      const directionalLight2 = new THREE.DirectionalLight(0xffffff, lightIntensity);
      directionalLight2.position.set(objectCenter.x - lightDistance, objectCenter.y + lightDistance, objectCenter.z);
      directionalLight2.castShadow = true;
      scene.add(directionalLight2);

      const directionalLight3 = new THREE.DirectionalLight(0xffffff, lightIntensity);
      directionalLight3.position.set(objectCenter.x, objectCenter.y + lightDistance, objectCenter.z + lightDistance);
      directionalLight3.castShadow = true;
      scene.add(directionalLight3);
    };

    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('three/examples/jsm/libs/draco/gltf');
    loader.setDRACOLoader(dracoLoader);

    const loadModel = (modelPath) => {
      if (currentScene) {
        scene.remove(currentScene);
      }

      loader.load(
        modelPath,
        (gltf) => {
          gltf.scene.traverse((node) => {
            if (node.isMesh) {
              node.castShadow = true;
              node.receiveShadow = true;
              node.material.needsUpdate = true;
            }
          });

          currentScene = gltf.scene;
          currentScene.position.set(0, 0, 0);
          currentScene.rotation.set(0, 0, 0);
          currentScene.scale.set(scale, scale, scale);

          scene.add(currentScene);
          addLightsBasedOnObject(scene, currentScene);
        },
        undefined,
        (error) => {
          console.error(`Error loading model: ${modelPath}`, error);
        }
      );
    };

    loadModel(currentModel);

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    const handleMouseMove = (event) => {
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);

      const intersects = raycaster.intersectObjects(scene.children, true);
      if (intersects.length > 0) {
        setHoveredPart(intersects[0].object.name || 'Unknown part');
      } else {
        setHoveredPart('');
      }
    };

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('resize', handleResize);

    const axesScene = new THREE.Scene();
    const axesHelper = new THREE.AxesHelper(0.1);
    axesScene.add(axesHelper);

    const axesCamera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, 0.01, 10);
    axesCamera.position.set(1, 1, 1);
    axesCamera.lookAt(0, 0, 0);

    const axesRenderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    axesRenderer.setSize(100, 100);
    axesRenderer.domElement.style.position = 'absolute';
    axesRenderer.domElement.style.top = '10px';
    axesRenderer.domElement.style.left = '10px';
    axesRenderer.setClearColor(0x000000, 0);
    mountRef.current.appendChild(axesRenderer.domElement);

    const animate = () => {
      controls.update();
      renderer.render(scene, camera);
      axesRenderer.render(axesScene, axesCamera);
      frameId = requestAnimationFrame(animate);
    };
    animate();

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(frameId);
      mountRef.current.removeChild(renderer.domElement);
      mountRef.current.removeChild(axesRenderer.domElement);
    };
  }, [currentModel, scale]);

  return (
    <div className="anatomy-app">
      <header className="app-header">
        <center>
        <h1>Anatomy Explorer</h1>
        </center>
      </header>
      <p class="app-description">
    Welcome to the Anatomy Explorer! This interactive tool allows you to explore the human body's different systems in 3D.
    Use your mouse to interact with the model:
    <ul>
        <li><strong>Zoom:</strong> Scroll your mouse wheel up or down to zoom in and out.</li>
        <li><strong>Rotate:</strong> Click and drag with the left mouse button to rotate the model and view it from different angles.</li>
        <li><strong>Pan:</strong> Hold the right mouse button or Ctrl key while clicking and dragging to move the model around the screen.</li>
        <li><strong>Reset View:</strong> Use the controls in the corner or double-click the canvas to reset the view to its original position.</li>
    </ul>
    Click on the buttons above to load and explore different anatomical systems like the muscular, nervous, circulatory, and gastrointestinal systems. Adjust the model’s scale with the slider and hover over parts of the model to learn more about each structure.
</p>

      <div className="app-controls">
        <button className="app-button" onClick={() => setCurrentModel('muscles.glb')}>Muscular System</button>
        <button className="app-button" onClick={() => setCurrentModel('CNS2.glb')}>Nervous System</button>
        <button className="app-button" onClick={() => setCurrentModel('Skeleton.glb')}>Skeletal System</button>
        <button className="app-button" onClick={() => setCurrentModel('Circulatory_system.glb')}>Female Circulatory System</button>
        <button className="app-button" onClick={() => setCurrentModel('Circulatory_system2.glb')}>Male Circulatory System</button>
        <button className="app-button" onClick={() => setCurrentModel('gastrointestinal_tract.glb')}>Gastrointestinal System</button>
      </div>

      <div className="app-slider">
        <label htmlFor="scale-slider">Model Scale: {scale.toFixed(2)}</label>
        <input
          id="scale-slider"
          type="range"
          min="0.1"
          max="2"
          step="0.1"
          value={scale}
          onChange={(e) => setScale(parseFloat(e.target.value))}
        />
      </div>

      <div className="app-canvas" ref={mountRef}></div>

      {hoveredPart && (
        <div className="tooltip">
          {hoveredPart}
        </div>
      )}
    </div>
  );
};

export default AnatomyThreeScene;
