import React, { useEffect, useState } from 'react';
import p5 from 'p5';
import '../App.css';

const Sketch = () => {
  const [gravity, setGravity] = useState(-9.8);
  const [xForce, setXForce] = useState(0);
  const [yForce, setYForce] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [mass, setMass] = useState(1);

  let sketch = function (p) {
    let x = 100;
    let y = 100;
    let xspeed = 0;
    let yspeed = 0;

    p.setup = function () {
      p.createCanvas(p.windowWidth, p.windowHeight);
    };

    p.draw = function () {
      p.background(0);

      if (isPlaying) {
        xspeed += (xForce / mass) * (p.deltaTime / 1000);
        // Gravity and mass are now factored into the Y speed calculation
        yspeed += ((-yForce / mass) - (gravity * mass)) * (p.deltaTime / 1000); 

        x += xspeed * (p.deltaTime / 1000);
        y += yspeed * (p.deltaTime / 1000);

        if (x > p.width - 24 || x < 24) {
          xspeed = xspeed * -1;
        }

        if (y > p.height - 24 || y < 24) {
          yspeed = yspeed * -1;
        }
      }

      p.fill(255);
      p.ellipse(x, y, 48, 48);
    };
  };

  useEffect(() => {
    let myp5 = new p5(sketch);
    return () => { myp5.remove() };
  }, [gravity, xForce, yForce, mass, isPlaying]);

  return (
    <div className="bouncing-ball-container">
      <h1 className="app-title">Bouncing Ball</h1>
      <p className="app-description">
        This interactive visualization demonstrates a bouncing ball that moves around the screen, changing direction when it hits the edge. This behavior mimics the physics of a real bouncing ball, illustrating key principles of motion and forces.
      </p>
      <div className="controls-container">
        <div className="control-input">
          <label>Gravity:</label>
          <input type="number" step="0.1" value={gravity} onChange={(e) => setGravity(e.target.value)} />
        </div>
        <div className="control-input">
          <label>X Force:</label>
          <input type="number" step="0.1" value={xForce} onChange={(e) => setXForce(e.target.value)} />
       
        </div>
        <div className="control-input">
          <label>Y Force:</label>
          <input type="number" step="0.1" value={yForce} onChange={(e) => setYForce(e.target.value)} />
          
        </div>
        <div className="control-input">
          <label>Mass:</label>
          <input type="number" step="0.1" value={mass} onChange={(e) => setMass(e.target.value)} />
        </div>
        <button onClick={() => setIsPlaying(!isPlaying)}>{isPlaying ? 'Pause' : 'Play'}</button>
      </div>
      <defs>
        <marker id="arrow" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="4" markerHeight="4" orient="auto-start-reverse">
          <path d="M 0 0 L 10 5 L 0 10 z" />
        </marker>
      </defs>
    </div>
  );
};

export default Sketch;
