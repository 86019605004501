import './contactus.css';


function ContactUsPage() {
    return (
        <div className="contact-us-container">
            <div className="contact-us-content">
                <h1 id="Contact">Contact Us</h1>
                <p>For inquiries, please contact us at:</p>
                <a href="mailto:andrewb.andrewslearning@gmail.com" className="contact-email">
                    andrewb.andrewslearning@gmail.com
                </a>
                <p className="response-time">Questions will be answered within 24 hours.</p>
            </div>
        </div>
    );
}

export default ContactUsPage;
