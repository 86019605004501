import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';

const Section = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div ref={ref} className={`fade-in-section ${inView ? 'is-visible' : ''}`}>
      {children}
    </div>
  );
};

const HomePage = () => {
  return (
    <Container fluid className="p-3 text-black bg-white text-center">
     
      <Section>
        <h1 >Welcome to Our Interactive Learning Platform!</h1>
        <p>
          Explore and understand complex concepts of Physics, Mathematics, Chemistry, and Biology.
        </p>
        <p>
          <Button variant="primary">Learn More</Button>
        </p>
        
      </Section>
      
      <Section>
      <h2>Interactive Learning Tools</h2>
        <p>
          We offer a variety of interactive learning tools. From virtual labs in chemistry to interactive graphs in mathematics, we provide a hands-on learning experience that fosters curiosity and exploration.
        </p>
        
        
        <video
  src="https://andrewslearningimage-bucket.s3.amazonaws.com/media/Gifs/Homepageproteingif.mov"
  alt="Educational Website"
  fluid className="section-image"
  autoPlay
  loop
  muted
  playsInline
>
</video>
      </Section>
      <Section>
        <h2>Benefit of "Top-Down Learning"</h2>
        <p>
          Our platform supports a "Top-Down" learning approach. Begin with big-picture concepts, then dig deeper into the details. This contextual learning makes comprehension easier and more intuitive.
        </p>
        <video
  src="https://andrewslearningimage-bucket.s3.amazonaws.com/media/Gifs/momentumgif.mov"
  alt="Educational Website"
  fluid className="section-image"
  autoPlay
  loop
  muted
  playsInline
>
</video>
      </Section>
      <Section>
        <h2>Real Life Scenarios</h2>
        <p>
          We believe in learning by doing. Our platform provides real-life scenarios for better understanding and application of concepts. Learn the laws of physics by virtually launching rockets, or understand chemical reactions by virtually mixing substances!
        </p>
        <video
  src="https://andrewslearningimage-bucket.s3.amazonaws.com/media/Gifs/algebrafunctiongif.mov"
  alt="Educational Website"
  fluid className="section-image"
  autoPlay
  loop
  muted
  playsInline
>
</video>
      </Section>
      <Section>
      <h2>The Wonders of STEM</h2>
        <p>
          Unveil the mysteries and embrace the exciting realms of Science, Technology, Engineering, and Mathematics. Discover how the principles of STEM govern the world around you.
        </p>
       
      </Section>
      <br/>
      <br/>
      <br/>
      <br/>
    </Container>
  );
};

export default HomePage;
