import React, { useState, useEffect } from 'react';
import './LoopVisualizer.css';

const LoopVisualizer = () => {
  const [iterations, setIterations] = useState(5);
  const [position, setPosition] = useState(0);
  const [visited, setVisited] = useState([]);

  useEffect(() => {
    const moveRobot = setInterval(() => {
      if (position < iterations) {
        setPosition((prevPosition) => prevPosition + 1);
        setVisited((prevVisited) => [...prevVisited, position]);
      } else {
        clearInterval(moveRobot);
        setPosition(0);
        setVisited([]);
      }
    }, 1000);

    return () => clearInterval(moveRobot);
  }, [position, iterations]);

  return (
    <div className="loop-visualizer-container">
      <div className="loop-visualizer">
        {Array.from({ length: iterations }, (_, i) => (
          <div className="spot" key={i}>
            {visited.includes(i) && "🔵"}
            {position - 1 === i && "🤖"}
          </div>
        ))}
      </div>

      <input
        type="number"
        value={iterations}
        onChange={(e) => setIterations(Math.min(10, Math.max(1, e.target.value)))}
        className="iteration-input"
      />

      <div className="code-display">
        <pre>
          <code>
            {`for i in range(${iterations}):
    # Robot moving to position i
    move_robot_to_position(i)`}
          </code>
        </pre>

      </div>
      <br/>
      <br/>
    </div>
  );
};

export default LoopVisualizer;
