import React from 'react';
import { Navbar } from 'react-bootstrap';
import '../Header.css';

const Header = () => {
  return (
    <header className='Header'>
      
      <img 
          id='HeaderLogo'
          src="https://andrewslearningimage-bucket.s3.amazonaws.com/media/ALClogo.png"
          alt="Educational Website"
          className='mb-2'
        />
       <div className='leftcolumn'>
           <video
            src="https://andrewslearningimage-bucket.s3.amazonaws.com/media/Gifs/spinningTF.mp4"
            alt="Educational Website"
            className="header-video"
            autoPlay
            loop
            muted
            playsInline
          />
            <div className="header-content">
          <center className='GreetingTextandHeaderSubtitle'>
      {/*   <h1 >Undergoing Maintanence</h1>*/} 
            <h3 id='GreetingText'>We make learning easy and fun!</h3>
            <p className='HeaderSubtitle'>Explore a world of STEM knowledge with our expert-crafted educational tools. <br />
            Begin your path to success today!</p>
           <a href="/AdaptiveAssessments#Assessments" className='HeaderCta'>Start Learning Now</a>
          </center>
       
        </div>
        </div>
        
      
        
      
    </header>
  );
};

export default Header;
