import React, { useState } from 'react';
import '../SetSimulator.css'; 

const SetSimulator = () => {
  const [setA, setSetA] = useState([]);
  const [setB, setSetB] = useState([]);
  const [result, setResult] = useState([]);

  const handleUnion = () => {
    setResult(Array.from(new Set([...setA, ...setB])));
  };

  const handleIntersection = () => {
    setResult(setA.filter(x => setB.includes(x)));
  };

  const handleComplement = () => {
    setResult(setA.filter(x => !setB.includes(x)));
  };

  const handleSetChange = (e, setFunc) => {
    let set = e.target.value.split(',').map(strNum => parseInt(strNum.trim()));
    setFunc(set);
  };

  return (
    <div className="set-simulator">
      <h2>Set Operations Simulator</h2>
      <p>This tool helps you visualize basic operations on sets. A set is a collection of distinct objects, considered as an object in its own right. Sets are one of the fundamental concepts in mathematics. Here we explore three basic operations: Union, Intersection, and Complement.</p>
      
      <div>
        <h3>Set A</h3>
        <input type="text" onChange={(e) => handleSetChange(e, setSetA)} placeholder="Enter numbers separated by commas" />
        <ul className="set">
          {setA.map((num, idx) => (
            <li key={idx} className={result.includes(num) ? 'highlight' : ''}>{num}</li>
          ))}
        </ul>
      </div>
      
      <div>
        <h3>Set B</h3>
        <input type="text" onChange={(e) => handleSetChange(e, setSetB)} placeholder="Enter numbers separated by commas" />
        <ul className="set">
          {setB.map((num, idx) => (
            <li key={idx} className={result.includes(num) ? 'highlight' : ''}>{num}</li>
          ))}
        </ul>
      </div>
      
      <h3>Operations</h3>
      <p>Select an operation to perform on the sets. The result will be highlighted in the set lists.</p>
      <button onClick={handleUnion}>Union</button>
      <p>Union: The union of two sets A and B is the set of elements which are in A, in B, or in both A and B.</p>
      
      <button onClick={handleIntersection}>Intersection</button>
      <p>Intersection: The intersection of two sets A and B is the set of elements which are in both A and B.</p>
      
      <button onClick={handleComplement}>Complement of A</button>
      <p>Complement: The complement of set A with respect to set B is the set of elements which are in B but not in A.</p>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>

    </div>
  );
};

export default SetSimulator;

