import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
    return (
        <div id="About" className="aboutUsContainer">
            
            <div className="teamMember">
                <img
                    className="memberPhoto"
                    src="https://andrewslearningimage-bucket.s3.amazonaws.com/media/Aboutus_image.jpeg"
                    alt="Andrew Browne"
                />
                <h2 className="memberName">Andrew Browne, B.Sc. (Biochemistry), PhD Candidate (Chemistry)</h2>
                <p className="memberTitle">Founder, Instructor, Developer</p>
                <p className="memberBio">
                <p>Delve into the world of STEM with our educational platform, where learning transcends the conventional to become a captivating and interactive experience in Science, Technology, Engineering, and Mathematics.</p>
                
                <p>The platform is spearheaded by Andrew Browne, a zealous 5th-year PhD candidate in Biochemistry. His commitment to education stems from a foundational belief that genuine understanding is nurtured through hands-on and practical learning experiences. Andrew's academic journey through the realms of biochemistry and his zest for technology have been the driving force behind our platform's goal: to spark a profound understanding and persistent curiosity in STEM among learners of all ages.</p>
                
                <p>Our platform does not just impart knowledge; it aims to ignite inspiration. Upholding the vision that the future of education is to evolve past traditional teaching methods, our platform welcomes students, educators, and the inherently curious to experience those pivotal 'eureka' moments that resonate with every learner.</p>
                </p>
            </div>
            <div className="teamMember">
    <img
        className="memberPhoto"
        src="https://andrewslearningimage-bucket.s3.amazonaws.com/media/selfy.jpg"
        alt="Jieqiong Fang"
    />
    <h2 className="memberName">Jieqiong (Lynn) Fang, M.Sc. (Analytical Chemistry), PhD Candidate (Chemistry)</h2>
    <p className="memberTitle">Instructor, Content Creator</p>
    <p className="memberBio">
        Jieqiong (Lynn) Fang is a dedicated fifth-year Ph.D. student at Georgia State University, where she is part of Dr. Suri S. Iyer's renowned research group. Specializing in analytical chemistry, Lynn's current research focuses on developing point-of-care assays for biologically relevant analytes, a field that blends her expertise in small molecule synthesis, nanoparticle synthesis, and sandwich assay development.
        <br/><br/>
        With a strong foundation in both theoretical knowledge and practical skills, Lynn has amassed over four years of experience in small molecule synthesis, utilizing techniques such as H-NMR, C-NMR, 2D-NMR, HPLC, and Mass Spectrometry. Her proficiency extends to mesoporous nanoparticle synthesis and modification, where she employs IR, UV-Vis, and Fluorescence spectroscopy. In the realm of bioassays, she has developed ELISA-based techniques for sensitive and specific detection of target molecules.
        <br/><br/>
        Lynn's academic journey is marked by a series of accolades and contributions to the scientific community. She holds a Master's degree in Analytical Chemistry from Georgia State University and a Bachelor's degree in Polymer Science and Engineering from East China University of Science and Technology. Her scholarly output includes multiple peer-reviewed publications in prestigious journals, highlighting her work on point-of-care assays and nanoparticle-based detection systems.
        <br/><br/>
        Her commitment to education and mentorship is evident through her role in guiding undergraduate students and her recognition with the Graduate Teaching Award at the Master's level from Georgia State University. Lynn's dedication to advancing scientific understanding and practical applications aligns perfectly with the mission of our STEM educational platform.
        <br/><br/>
        Joining forces with Andrew Browne, Lynn aims to inspire the next generation of scientists by integrating hands-on, practical learning experiences into the educational journey. Together, they strive to make STEM education captivating, interactive, and accessible, fostering those pivotal 'eureka' moments that ignite a lifelong passion for science, technology, engineering, and mathematics.
       
    </p>
</div>

<div className="teamMember">
    <img
        className="memberPhoto"
        src="https://andrewslearningimage-bucket.s3.amazonaws.com/evan.png"
        alt="Evan"
        
    />
    <h2 className="memberName">Evan, Ph.D. Candidate (Organic Chemistry)</h2>
    <p className="memberTitle">Instructor, Content Creator</p>
    <p className="memberBio">
        Meet Evan, a passionate PhD candidate in organic chemistry at the University of Massachusetts Lowell. With a Bachelor's degree in Biochemistry and Molecular Biology, complemented by minors in Neuroscience and Mathematics, Evan's academic and research journey is a testament to a deep-rooted dedication to scientific discovery and education. Evan has synthesized numerous novel small molecules and is currently focused on applied synthetic chemistry and biochemistry, particularly in the detection of critical biomarkers.
        <br/><br/>
        Evan's research journey began in a neurobiochemistry lab working on Parkinson’s disease before transitioning to synthetic chemistry. Now, he applies synthetic organic chemistry to biomarker detection. His extensive lab skills include HPLC, LC-MS, NMR, TEM, and chromatography, among others. He has presented his work at various conferences and has been involved in projects ranging from therapeutic drug monitoring to the synthesis of novel NIR-1 dyes. Evan has also mentored other students, managed lab operations, and developed various technological projects.
        <br/><br/>
        Beyond the lab, Evan is an avid adventurer. He enjoys hiking, climbing, snowboarding, and going to the gym, finding inspiration and balance in the great outdoors. Evan’s enthusiasm for both scientific exploration and outdoor activities shapes a holistic approach to learning and teaching, aiming to ignite a lifelong passion for STEM in students of all ages.
        <br/><br/>
        Evan's commitment to education extends beyond research and adventure. He actively participates in outreach programs, sharing his knowledge and experiences with young learners and aspiring scientists. By fostering curiosity and critical thinking, Evan hopes to inspire the next generation of innovators and problem-solvers, ensuring a bright future for STEM fields.
    </p>
</div>
        </div>
    );
};

export default AboutUs;
