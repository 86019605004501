import React, { useState } from 'react';


const MolesToWeightCalculator = () => {
  const [moles, setMoles] = useState(0);
  const [molarMass, setMolarMass] = useState(0);

  const handleMolesChange = (event) => {
    setMoles(event.target.value);
  };

  const handleMolarMassChange = (event) => {
    setMolarMass(event.target.value);
  };

  const calculateWeight = () => {
    return moles * molarMass;
  };

  return (
    <div>
      <h1>Moles to Weight Calculator</h1>
      <label>
        Moles:
        <input type="number" value={moles} onChange={handleMolesChange} />
      </label>
      <label>
        Molar Mass (g/mol):
        <input
          type="number"
          value={molarMass}
          onChange={handleMolarMassChange}
        />
      </label>
      <p>
        Weight: <strong>{calculateWeight()} grams</strong>
      </p>
      <br />
    </div>
  );
};

const WeightToMolesCalculator = () => {
  const [mass, setMass] = useState(0);
  const [molarMass, setMolarMass] = useState(0);

  const handleMassChange = (event) => {
    setMass(event.target.value);
  };

  const handleMolarMassChange = (event) => {
    setMolarMass(event.target.value);
  };

  const calculateMoles = () => {
    return mass / molarMass;
  };

  return (
    <div>
      <h1>Weight to Moles Calculator</h1>
      <label>
        Mass:
        <input type="number" value={mass} onChange={handleMassChange} />
      </label>
      <label>
        Molar Mass (g/mol):
        <input
          type="number"
          value={molarMass}
          onChange={handleMolarMassChange}
        />
      </label>
      <p>
        Moles: <strong>{calculateMoles()} moles</strong>
      </p>
    </div>
  );
};

const MolarityToWeight = () => {
  const [molarity, setMolarity] = useState(0);
  const [molarMass, setMolarMass] = useState(0);

  const handleMolarityChange = (event) => {
    setMolarity(event.target.value);
  };

  const handleMolarMassChange = (event) => {
    setMolarMass(event.target.value);
  };

  const calculateWeight = () => {
    return molarity * molarMass;
  };

  return (
    <div>
      <h1>Molarity to Weight Calculator</h1>
      <label>
        Molarity:
        <input type="number" value={molarity} onChange={handleMolarityChange} />
      </label>
      <label>
        Molar Mass (g/mol):
        <input
          type="number"
          value={molarMass}
          onChange={handleMolarMassChange}
        />
      </label>
      <p>
        Weight: <strong>{calculateWeight()} grams</strong>
      </p>
    </div>
  );
};

const AllCalculators = () => {
  return (
    <div>
      
      <center>
      <MolesToWeightCalculator />
      <WeightToMolesCalculator />
      <MolarityToWeight />
      </center>
    </div>
  );
};

export default AllCalculators;
