import React, { useEffect } from 'react';

const MolecularEditor = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'jsme/jsme.nocache.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
        <br/>
        <br/>
        <center>
      <div
        code="JME.class"
        name="JME"
        archive="JME.jar"
        width="760"
        height="515"
        id="JME"
      >
        You have to enable JavaScript in your browser to use JSME!
      </div>
      </center>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  );
};

export default MolecularEditor;
