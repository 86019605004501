import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const YuhloaderComponent = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    // Set up the scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.set(0, 0, 4);

    // Set up lights
    const ambientLight = new THREE.HemisphereLight(0xffffff, 0xbbbbff, 0.5);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(0.2, 1, 1);
    scene.add(ambientLight);
    scene.add(directionalLight);

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setAnimationLoop(renderScene);
    renderer.setClearColor(0xaaaaaa);

    // Append the renderer to the DOM
    mountRef.current.appendChild(renderer.domElement);

    // Load the GLTF file
    const loader = new GLTFLoader();
    loader.load('/Chemistry.glb', (gltf) => {
      const beakerCollection = gltf.scene;
      beakerCollection.positionX = 70;
      scene.add(beakerCollection);
    }, undefined, (error) => {
      console.error('An error occurred while loading the GLTF file:', error);
    });

    
    // Create star geometry
    const createStarGeometry = (innerRadius = 0.4, outerRadius = 0.8, points = 5) => {
      const shape = new THREE.Shape();
      const PI2 = Math.PI * 2;
      const inc = PI2 / (points * 2);
      let inner = true;

      shape.moveTo(outerRadius, 0); // Start at the outer radius point
      for (let theta = inc; theta < PI2; theta += inc) {
        const radius = inner ? innerRadius : outerRadius;
        shape.lineTo(Math.cos(theta) * radius, Math.sin(theta) * radius);
        inner = !inner;
      }
      shape.lineTo(outerRadius, 0); // Close the shape

      return new THREE.ShapeGeometry(shape);
    };

    const geometry = createStarGeometry();
    const material = new THREE.MeshStandardMaterial({ color: 0xff0000 });
    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);

    const controls = new OrbitControls(camera, renderer.domElement);

    // Render function
    function renderScene() {
      mesh.rotateY(0.01);
      renderer.render(scene, camera);
    }

    // Clean up on component unmount
    return () => {
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <div>
      <div ref={mountRef}></div>
    </div>
  );
};

export default YuhloaderComponent;
