import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './BlogList.css'

const BlogList = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(state => state.userLogin.userInfo);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await axios.get('https://api.andrewslearning.com/api/posts/');
      const sortedPosts = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      setPosts(sortedPosts);
    } catch (error) {
      console.error('Error retrieving posts:', error);
      setError(error.toString());
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      await axios.delete(`https://api.andrewslearning.com/api/posts/${postId}/`);
      const updatedPosts = posts.filter((post) => post.id !== postId);
      setPosts(updatedPosts);
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US');
  };

  const filteredPosts = posts.filter(post =>
    post.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
 
      <div className="blog-intro">
        <center>
          <h2>Check out our Blog!</h2>
        </center>
        <p>
          Stay updated with the latest trends and insights in education, technology, and more. Our blog provides valuable information to keep you informed about STEM topics.
        </p>  
       
      </div>
      <center>
        <h1 id="Posts">Blog</h1>
      </center>
      <input
        type="text"
        placeholder="Search posts..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-bar"
      />
      <div className="posts-grid">
        {filteredPosts.map((post) => (
          <div key={post.id} className="post-card">
            <img src={post.image || 'https://via.placeholder.com/150'} alt={post.title} className="post-image" />
            <div className="post-content">
              <h2 className="post-title">
                <Link to={`/posts/${post.id}`}>{post.title}</Link>
              </h2>
              <p className="post-info">
                Date: {formatDate(post.timestamp)} | Author: {post.author.username}
              </p>
              <p className="post-snippet">{post.snippet}</p>
              {currentUser && post.author.username === currentUser.username && (
                <button className="delete-button" onClick={() => handleDeletePost(post.id)}>Delete</button>
              )}
            </div>
          </div>
        ))}
      </div>
      <br/><br/><br/>
    </div>
  );
};

export default BlogList;
