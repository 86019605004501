import React from 'react';
import '../App.css'; // Make sure to create this file and import it

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footerContainer">
        <p className="text-center">© 2023 Andrews Learning. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
