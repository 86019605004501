import React, { useRef, useEffect, useState } from 'react';

function WhiteBoard() {
    const canvasRef = useRef(null);
    const contextRef = useRef(null);
    const [drawing, setDrawing] = useState(false);
    const [imgSrc, setImgSrc] = useState(null);
    const [imgWidth, setImgWidth] = useState(100);
    const [imgHeight, setImgHeight] = useState(100);
    const [imgX, setImgX] = useState(100);
    const [imgY, setImgY] = useState(100);
    const [lineColor, setLineColor] = useState('black');

    useEffect(() => {
        const canvas = canvasRef.current;
        canvas.width = window.innerWidth * 2;
        canvas.height = window.innerHeight * 2;
        canvas.style.width = `${window.innerWidth}px`;
        canvas.style.height = `${window.innerHeight}px`;

        const context = canvas.getContext('2d');
        context.scale(2, 2);
        context.lineCap = "round";
        context.strokeStyle = lineColor;
        context.lineWidth = 5;
        contextRef.current = context;

        if (imgSrc) {
            const img = new Image();
            img.onload = () => {
                context.drawImage(img, imgX, imgY, imgWidth, imgHeight);
            };
            img.src = imgSrc;
        }
    }, [imgSrc, imgWidth, imgHeight, imgX, imgY, lineColor]);

    const startDrawing = ({ nativeEvent }) => {
        const { offsetX, offsetY } = nativeEvent;
        contextRef.current.beginPath();
        contextRef.current.moveTo(offsetX, offsetY);
        setDrawing(true);
    };

    const finishDrawing = () => {
        contextRef.current.closePath();
        setDrawing(false);
    };

    const draw = ({ nativeEvent }) => {
        if (!drawing) {
            return;
        }
        const { offsetX, offsetY } = nativeEvent;
        contextRef.current.lineTo(offsetX, offsetY);
        contextRef.current.stroke();
    };

    const handleImageUpload = event => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setImgSrc(reader.result);
        };
        reader.readAsDataURL(file);
    };

    return (
        <div>
            <input type="file" onChange={handleImageUpload} style={{ display: 'block' }}/>
            <label>Width:</label>
            <input type="number" value={imgWidth} onChange={e => setImgWidth(e.target.value)} />
            <label>Height:</label>
            <input type="number" value={imgHeight} onChange={e => setImgHeight(e.target.value)} />
            <label>X:</label>
            <input type="number" value={imgX} onChange={e => setImgX(e.target.value)} />
            <label>Y:</label>
            <input type="number" value={imgY} onChange={e => setImgY(e.target.value)} />
            <label>Line color:</label>
            <input type="color" value={lineColor} onChange={e => setLineColor(e.target.value)} />
            <canvas 
                onMouseDown={startDrawing} 
                onMouseUp={finishDrawing}
                onMouseMove={draw}
                ref={canvasRef}
                style={{cursor: 'crosshair'}}
            />
        </div>
    );
}

export default WhiteBoard;


