import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export function initThreeJS(containerId, atomicNumber = 12, atomicMass = 18,numberOfElectrons = 12) {
  const container = document.getElementById(containerId);

  if (!container) {
    console.error(`Container with ID ${containerId} not found.`);
    return;
  }

  let isPaused = false; // State to track whether the animation is paused

  const atomicNumberElement = document.createElement('div');
  const atomicMassElement = document.createElement('div');
  atomicNumberElement.id = 'atomicNumber';
  atomicMassElement.id = 'atomicMass';
  atomicNumberElement.style.position = 'absolute';
  atomicNumberElement.style.top = '10px';
  atomicNumberElement.style.right = '10px';
  atomicNumberElement.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
  atomicNumberElement.style.padding = '10px';
  atomicMassElement.style.position = 'absolute';
  atomicMassElement.style.top = '50px';
  atomicMassElement.style.right = '10px';
  atomicMassElement.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
  atomicMassElement.style.padding = '10px';

  document.body.appendChild(atomicNumberElement);
  document.body.appendChild(atomicMassElement);

  const updateAtomicInfo = () => {
    atomicNumberElement.innerText = `Atomic Number: ${atomicNumber}`;
    atomicMassElement.innerText = `Atomic Mass: ${atomicMass}`;
  };

  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, 1000);
  camera.position.z = 10;

  const renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setSize(container.clientWidth, container.clientHeight);
  container.appendChild(renderer.domElement);

  const ambientLight = new THREE.AmbientLight(0x404040, 5); // Increased intensity
  scene.add(ambientLight);

  const pointLight1 = new THREE.PointLight(0xffffff, 5, 100);
  pointLight1.position.set(10, 10, 10);
  scene.add(pointLight1);

  const pointLight2 = new THREE.PointLight(0xffffff, 5, 100);
  pointLight2.position.set(-10, -10, -10);
  scene.add(pointLight2);

  const directionalLight1 = new THREE.DirectionalLight(0xffffff, 3);
  directionalLight1.position.set(1, 1, 1).normalize();
  scene.add(directionalLight1);

  const directionalLight2 = new THREE.DirectionalLight(0xffffff, 3);
  directionalLight2.position.set(-1, -1, -1).normalize();
  scene.add(directionalLight2);

  scene.rotation.x = Math.PI / 16;
  scene.rotation.y = Math.PI / 16;

  const protonMaterial = new THREE.MeshPhongMaterial({ color: 0xff0000 });
  const neutronMaterial = new THREE.MeshPhongMaterial({ color: 0x00ff00 });
  const electronMaterial = new THREE.MeshPhongMaterial({ color: 0x0000ff });

  let ringsOffset = false;
  const ringOffsetAngle = 15;

  const nucleus = new THREE.Group();
  const electrons = new THREE.Group();

  const createNucleus = () => {
    nucleus.clear();

    for (let i = 0; i < atomicNumber; i++) {
      const proton = new THREE.Mesh(new THREE.SphereGeometry(0.2, 32, 32), protonMaterial);
      proton.position.set(Math.random() * 0.5, Math.random() * 0.5, Math.random() * 0.5);
      proton.name = 'proton';
      nucleus.add(proton);
    }

    const neutrons = atomicMass - atomicNumber;
    for (let i = 0; i < neutrons; i++) {
      const neutron = new THREE.Mesh(new THREE.SphereGeometry(0.2, 32, 32), neutronMaterial);
      neutron.position.set(Math.random() * 0.5, Math.random() * 0.5, Math.random() * 0.5);
      neutron.name = 'neutron';
      nucleus.add(neutron);
    }

    scene.add(nucleus);
  };

  const numberOfRings = (atomicNumber) => {
    if (atomicNumber <= 2) {
      return 1;
    } else {
      return Math.ceil((atomicNumber - 2) / 8) + 1;
    }
  };

  const createOrbit = (radius, angle) => {
    const geometry = new THREE.RingGeometry(radius - 0.05, radius + 0.05, 64);
    const material = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide });
    const ring = new THREE.Mesh(geometry, material);
    ring.rotation.x = Math.PI / 2;
    ring.rotation.z = angle;
    scene.add(ring);
  };

  const ringCreation = (numRings) => {
    for (let i = 1; i <= numRings; i++) {
      const angle = ringsOffset ? i * (ringOffsetAngle * Math.PI / 180) : 0;
      createOrbit(i + 1, angle);
    }
  };

  const createElectron = (radius, angleOffset, totalElectrons, ringIndex) => {
    const electron = new THREE.Mesh(new THREE.SphereGeometry(0.1, 32, 32), electronMaterial);
    const angle = ringsOffset ? ringIndex * (ringOffsetAngle * Math.PI / 180) : 0;
    electron.userData = { radius, angleOffset, totalElectrons, angle };
    electrons.add(electron);
  };

  const electronCreation = (numberOfElectrons) => {
    electrons.clear();
    const shellCapacity = [2]; // You can extend this array to include capacities for additional shells
    let remainingElectrons = numberOfElectrons;
    let radius = 2;
    let shellIndex = 0;
  
    while (remainingElectrons > 0) {
      const currentShellCapacity = shellIndex < shellCapacity.length ? shellCapacity[shellIndex] : 8; // Default capacity is 8 for outer shells
      const electronsInShell = Math.min(remainingElectrons, currentShellCapacity);
      const angleOffset = (2 * Math.PI) / electronsInShell;
  
      for (let i = 0; i < electronsInShell; i++) {
        createElectron(radius, i * angleOffset, electronsInShell, shellIndex);
      }
  
      remainingElectrons -= electronsInShell;
      radius++;
      shellIndex++;
    }
  
    scene.add(electrons);
  };
  
  const redrawScene = () => {
    nucleus.clear();
    electrons.clear();
    scene.clear();
    scene.add(ambientLight, pointLight1, pointLight2, directionalLight1, directionalLight2);
    createNucleus();
    ringCreation(numberOfRings(atomicNumber));
    electronCreation(atomicNumber);
    updateAtomicInfo();
  };

  createNucleus();
  ringCreation(numberOfRings(atomicNumber));
  electronCreation(atomicNumber);
  updateAtomicInfo();

  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();

  const onDocumentMouseClick = (event) => {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObjects(nucleus.children, true);

    if (intersects.length > 0) {
      const intersectedObject = intersects[0].object;
      if (intersectedObject.name === 'proton') {
        atomicNumber += 1;
        atomicMass += 1;
      } else if (intersectedObject.name === 'neutron') {
        atomicMass += 1;
      }
      redrawScene();
    }
  };

  renderer.domElement.addEventListener('click', onDocumentMouseClick, false);

  function animateElectrons() {
    requestAnimationFrame(animateElectrons);
    if (!isPaused) {
      const time = Date.now() * 0.001;
      electrons.children.forEach((electron) => {
        const { radius, angleOffset, totalElectrons, angle } = electron.userData;
        const currentAngle = time + angleOffset;
        electron.position.x = Math.cos(currentAngle) * radius * Math.cos(angle);
        electron.position.y = Math.sin(angle) * radius;
        electron.position.z = Math.sin(currentAngle) * radius * Math.cos(angle);
      });
    }
    renderer.render(scene, camera);
  }

  animateElectrons();

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.dampingFactor = 0.25;
  controls.screenSpacePanning = false;
  controls.maxPolarAngle = Math.PI / 2;

  window.addEventListener('resize', () => {
    const width = container.clientWidth;
    const height = container.clientHeight;
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);
  });

  const toggleRingsOffset = () => {
    ringsOffset = !ringsOffset;
    redrawScene();
  };

  const buttonContainer = document.createElement('div');
  buttonContainer.className = 'button-container';
  document.body.appendChild(buttonContainer);

  const pauseButton = document.createElement('button');
  pauseButton.id = 'pause-button';
  pauseButton.innerText = 'Pause';
  pauseButton.className = 'control-button';
  pauseButton.addEventListener('click', () => {
    isPaused = !isPaused;
    pauseButton.innerText = isPaused ? 'Resume' : 'Pause';
  });
  buttonContainer.appendChild(pauseButton);
}
