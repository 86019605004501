import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart, validateDiscountCode } from '../actions/userActions';
import { useNavigate } from 'react-router-dom';
import './Cart.css';

const subscriptionOptions = {
    monthly: 7.99,
    annual: 70.00,
};

const Cart = () => {
    const [discountCode, setDiscountCode] = useState('');
    const [discountApplied, setDiscountApplied] = useState(false);
    const [selectedSubscriptionType, setSelectedSubscriptionType] = useState('monthly');
    
    const cartItems = useSelector(state => state.cart.cartItems);
    const totalCost = useSelector(state => state.cart.totalCost);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Ensure the subscription is added to the cart when the component mounts
    useEffect(() => {
        dispatch(addToCart(selectedSubscriptionType, 'subscription'));
    }, [dispatch, selectedSubscriptionType]);

    // Handlers
    const handleSubscriptionSelect = (type) => {
        setSelectedSubscriptionType(type);
        dispatch(addToCart(type, 'subscription'));
    };

    const applyDiscountCode = () => {
        if (!discountCode) return alert('Please enter a discount code.');
        if (discountApplied) return alert('Discount code already applied.');

        dispatch(validateDiscountCode(discountCode, cartItems));
        setDiscountApplied(true);
    };

    // Ensure totalCost is a number and handle cases where it's not
    const formattedTotalCost = totalCost ? Number(totalCost).toFixed(2) : "0.00";

    // Render
    return (
        <div id="Cartcontainer">
            <br />
            <br />
            <br />
            <div id="Cart" className="Cart">
                <h2>Unlock the full learning experience</h2>
                <p>Reach your learning goals fast with unlimited access to all courses</p>
                
                <div className="subscription-selection">
                    {Object.entries(subscriptionOptions).map(([type, cost]) => (
                        <button
                            key={type}
                            className={`subscription-option ${selectedSubscriptionType === type ? 'selected' : ''}`}
                            onClick={() => handleSubscriptionSelect(type)}
                        >
                            {`${type.charAt(0).toUpperCase() + type.slice(1)} - $${cost.toFixed(2)} ${type === 'annual' ? '/year' : '/month'}`}
                        </button>
                    ))}
                </div>
                <br />

                <div className="discount-code-section">
                    {discountApplied ? (
                        <p>Discount code applied! New price: ${formattedTotalCost}</p>
                    ) : (
                        <>
                            <input
                                type="text"
                                value={discountCode}
                                onChange={(e) => setDiscountCode(e.target.value)}
                                placeholder="Discount Code"
                            />
                            <button onClick={applyDiscountCode}>Apply Code</button>
                        </>
                    )}
                </div>

                <button onClick={() => navigate('/checkout')} className="proceed-to-checkout">
                    Subscribe now
                </button>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    );
};

export default Cart;
