// src/threeSetup.js

export function initThreeJS(containerId) {
    console.log("Initializing Three.js setup");
  
    import('three').then(THREE => {
      console.log("Three.js imported successfully", THREE);
  
      import('three/examples/jsm/controls/OrbitControls').then(({ OrbitControls }) => {
        console.log("OrbitControls imported successfully", OrbitControls);
  
        import('three/examples/jsm/loaders/GLTFLoader').then(({ GLTFLoader }) => {
          console.log("GLTFLoader imported successfully", GLTFLoader);
  
          const container = document.getElementById(containerId);
          console.log("Container fetched:", container);
  
          if (!container) {
            console.error(`Container with ID ${containerId} not found.`);
            return;
          }
  
          console.log(`Initializing Three.js in container: ${containerId}`);
  
          const scene = new THREE.Scene();
          const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
          camera.position.z = 10;
  
          const renderer = new THREE.WebGLRenderer({ antialias: true });
          renderer.setSize(window.innerWidth, window.innerHeight);
          renderer.setClearColor(0xDCDCDC, 1); // Set background color to skyblue
          container.appendChild(renderer.domElement);
          console.log('Renderer initialized and appended to container.');
  
          // Add lights
          const ambientLight = new THREE.AmbientLight(0x404040, 5); // Increased intensity
          scene.add(ambientLight);
          console.log('Ambient light added to the scene.');
  
          const pointLight1 = new THREE.PointLight(0xffffff, 5, 100);
          pointLight1.position.set(10, 10, 10);
          scene.add(pointLight1);
  
          const pointLight2 = new THREE.PointLight(0xffffff, 5, 100);
          pointLight2.position.set(-10, -10, -10);
          scene.add(pointLight2);
  
          const directionalLight1 = new THREE.DirectionalLight(0xffffff, 3);
          directionalLight1.position.set(1, 1, 1).normalize();
          scene.add(directionalLight1);
  
          const directionalLight2 = new THREE.DirectionalLight(0xffffff, 3);
          directionalLight2.position.set(-1, -1, -1).normalize();
          scene.add(directionalLight2);
          console.log('Lights added to the scene.');
  
          // Add axes helper
          const axesHelper = new THREE.AxesHelper(5);
          scene.add(axesHelper);
          console.log('AxesHelper added to the scene.');
  
          // Create a plane for object dragging
          const plane = new THREE.Plane();
          const planeNormal = new THREE.Vector3();
          const intersectionPoint = new THREE.Vector3();
          const offset = new THREE.Vector3();
   
         // Load the GLTF file
          const loader = new GLTFLoader();
          console.log('Loading GLTF file: /beaker_collection.glb');
          loader.load('/beaker_collection.glb', (gltf) => {
            console.log('GLTF loaded successfully:', gltf);
            const beakerCollection = gltf.scene;
  
            // Adjust position and scale
            beakerCollection.position.set(0, 0, 0); // Centering the model
            beakerCollection.scale.set(10, 10, 10); // Adjust scale as necessary
  
            // Adjust materials to handle transparency
            beakerCollection.traverse((child) => {
              if (child.isMesh) {
                child.material.transparent = false; // Disable transparency
                child.material.opacity = 1; // Set opacity to 1
              }
            });
  
            scene.add(beakerCollection);
            console.log('Beaker collection added to the scene.', beakerCollection);
  
            // Enable object picking
            let selectedObject = null;
            const raycaster = new THREE.Raycaster();
            const mouse = new THREE.Vector2();
  
            function onMouseMove(event) {
              mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
              mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  
              raycaster.setFromCamera(mouse, camera);
  
              if (selectedObject) {
                raycaster.ray.intersectPlane(plane, intersectionPoint);
                selectedObject.position.copy(intersectionPoint.sub(offset));
              }
            }
  
            function onMouseDown(event) {
              mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
              mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  
              raycaster.setFromCamera(mouse, camera);
              const intersects = raycaster.intersectObjects(beakerCollection.children, true);
  
              if (intersects.length > 0) {
                selectedObject = intersects[0].object;
                controls.enabled = false; // Disable orbit controls while dragging
  
                raycaster.ray.intersectPlane(plane, intersectionPoint);
                offset.copy(intersectionPoint).sub(selectedObject.position);
  
                planeNormal.copy(camera.position).normalize();
                plane.setFromNormalAndCoplanarPoint(planeNormal, selectedObject.position);
              }
            }
  
            function onMouseUp() {
              selectedObject = null;
              controls.enabled = true; // Re-enable orbit controls
            }
  
            window.addEventListener('mousemove', onMouseMove, false);
            window.addEventListener('mousedown', onMouseDown, false);
            window.addEventListener('mouseup', onMouseUp, false);
  
          }, undefined, (error) => {
            console.error('Error loading GLTF:', error);
          });
  
          // Add orbit controls
          const controls = new OrbitControls(camera, renderer.domElement);
          controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
          controls.dampingFactor = 0.25;
          controls.screenSpacePanning = false;
          controls.maxPolarAngle = Math.PI / 2;
          console.log('Orbit controls added.');
  
          // Handle window resize
          window.addEventListener('resize', () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            renderer.setSize(width, height);
            console.log('Window resized. Renderer and camera updated.');
          });
  
          // Animation loop
          function animate() {
            requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
          }
          animate();
          console.log('Animation loop started.');
        }).catch(err => console.error('Error loading GLTFLoader:', err));
      }).catch(err => console.error('Error loading OrbitControls:', err));
    }).catch(err => console.error('Error loading Three.js:', err));
  }
  