import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
   CART_ADD_SUCCESS,

    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_PROFILE_RESET,
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_CLEAR_ITEMS,
    CART_RESET_SUCCESS_MESSAGE,
    CART_ADD_ITEM_FAIL,
    APPLY_DISCOUNT,
    UPDATE_TOTAL_COST,

    VALIDATE_DISCOUNT_CODE_FAIL,
    VALIDATE_DISCOUNT_CODE_SUCCESS,
    VALIDATE_DISCOUNT_CODE_REQUEST,    

    
  } from '../constants/userConstants';

  import { saveCartToLocalStorage } from '../constants/utility'; // Adjust path if necessary
 
// Adjust the path based on your file structure


  export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_LOGIN_REQUEST:
        return { loading: true };
      case USER_LOGIN_SUCCESS:
        return { loading: false, userInfo: action.payload };
      case USER_LOGIN_FAIL:
        return { loading: false, error: action.payload };
      case USER_LOGOUT:
        return {};
      default:
        return state;
    }
  };

  export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_REGISTER_REQUEST:
        return { loading: true };
      case USER_REGISTER_SUCCESS:
        return { loading: false, userInfo: action.payload };
      case USER_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      case USER_LOGOUT:
        return {};
      default:
        return state;
    }
  };
  
  export const userDetailsReducer = (state = { user: {} }, action) => {
    switch (action.type) {
      case USER_DETAILS_REQUEST:
        return { ...state, loading: true };
      case USER_DETAILS_SUCCESS:
        return { loading: false, user: action.payload };
      case USER_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      
      default:
        return state;
    }
  };
  
  export const userUpdateProfileReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_UPDATE_PROFILE_REQUEST:
        return { loading: true };
      case USER_UPDATE_PROFILE_SUCCESS:
        return { loading: false, success: true, userInfo: action.payload };
      case  USER_UPDATE_PROFILE_FAIL:
        return { loading: false, error: action.payload };
      case  USER_UPDATE_PROFILE_RESET:
        return {}
      default:
        return state;
    }
  };

  const initialState = {
    cartItems: [],
    totalCost: '0.00', // Make sure this is a string if you're calling toFixed(2) on it
    discountAmount: 0,
    successMessage: '',
  };

 // In your reducers file (e.g., cartReducer.js)
 export const cartReducer = (state = { cartItems: [], successMessage: '', totalCost: 0, discountAmount: 0 }, action) => {
  switch (action.type) {
    case CART_ADD_ITEM: {
      const newItem = action.payload;
      const existItem = state.cartItems.find(item => item.id === newItem.id && item.type === newItem.type);
      console.log("Putting in",{newItem});
      // Check if the item already exists in the cart
      if (existItem) {
          // Replace existing item
          return {
              ...state,
              cartItems: state.cartItems.map(item =>
                  item.id === existItem.id && item.type === existItem.type ? newItem : item
              ),
          };
      } else {
          // Add new item
          return {
              ...state,
              cartItems: [...state.cartItems, newItem],
          };
      }
  }

      case CART_REMOVE_ITEM:
          return {
              ...state,
              cartItems: state.cartItems.filter(item => item.id !== action.payload.id || item.type !== action.payload.type),
          };

      case CART_CLEAR_ITEMS:
          return {
              ...state,
              cartItems: [],
              totalCost: 0, // Reset total cost as well
              discountAmount: 0, // Reset discount amount as well
          };

      case CART_ADD_ITEM_FAIL:
          return {
              ...state,
              failMessage: action.payload,
          };

      case CART_ADD_SUCCESS:
          return {
              ...state,
              successMessage: action.payload,
          };

      case CART_RESET_SUCCESS_MESSAGE:
          return {
              ...state,
              successMessage: '',
          };

      case UPDATE_TOTAL_COST:
          return {
              ...state,
              totalCost: action.payload,
          };

      case APPLY_DISCOUNT: {
          // Assuming APPLY_DISCOUNT action itself calculates and dispatches UPDATE_TOTAL_COST
          // No need to update totalCost here if UPDATE_TOTAL_COST action is dispatched after applying discount
          return {
              ...state,
              discountAmount: action.payload, // Assuming payload is discountAmount here
          };
      }
      case VALIDATE_DISCOUNT_CODE_REQUEST:
            return { ...state, loading: true, error: null };
        case VALIDATE_DISCOUNT_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                totalCost: action.payload.newTotal,
                discountAmount: action.payload.discountAmount,
                error: null,
            };
        case VALIDATE_DISCOUNT_CODE_FAIL:
            return { ...state, loading: false, error: action.payload };


      default:
          return state;
  }
};

