import axios from 'axios'
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_PROFILE_RESET,
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_CLEAR_ITEMS,
    CART_ADD_SUCCESS,
    UPDATE_TOTAL_COST,
    VALIDATE_DISCOUNT_CODE_FAIL,
    VALIDATE_DISCOUNT_CODE_SUCCESS,
    VALIDATE_DISCOUNT_CODE_REQUEST,
    SUBSCRIPTION_ADD_ITEM
    
} from '../constants/userConstants'

export const login = (username, password) => async (dispatch) =>{
    try{
        dispatch({
            type: USER_LOGIN_REQUEST
        })

        const config = {
            headers:{
                'Content-type':'application/json'
            }
        }

        const { data } = await axios.post(
            'https://api.andrewslearning.com/api/users/login',
            {'username':username, 'password':password },
            config
        )

        dispatch({
            type:USER_LOGIN_SUCCESS,
            payload:data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error){
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
        })
    }
}


export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch({ type : USER_LOGOUT })
}

export const register = (first_name,last_name, username, email, password) => async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
  
      const { data } = await axios.post(
        'https://api.andrewslearning.com/api/users/register',
        { first_name,last_name, username, email, password },
        config
      );
  
      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });
  
      localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

  export const getUserDetails = (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_DETAILS_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `https://api.andrewslearning.com/api/profile/${id}`,
        config
      )
      console.log(data); // Log data here
      
      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: data,
      })

    } catch (error) {
      dispatch({
        type: USER_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }


  export const updateUserProfile = (user) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_PROFILE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `https://api.andrewslearning.com/api/profile/update/`,
        user,
        config
      )
      console.log(data); // Log data here
      
      dispatch({
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: data,
      })

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      })

      localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
      dispatch({
        type: USER_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

  
  // In your actions file (e.g., userActions.js)
const calculateTotalCost = (cartItems) => {
    return cartItems.reduce((total, item) => total + item.cost * (item.quantity || 1), 0).toFixed(2);
  };

// Action to update total cost
export const updateTotalCost = () => (dispatch, getState) => {
  const { cartItems } = getState().cart;
  const newTotalCost = calculateTotalCost(cartItems);
  dispatch({
      type: UPDATE_TOTAL_COST,
      payload: newTotalCost,
  });
};

export const addToCart = (id, itemType) => async (dispatch, getState) => {
  console.log(`addToCart called with id: ${id}, itemType: ${itemType}`); // Debug log

  const { userLogin: { userInfo } } = getState();

  if (!userInfo) {
    dispatch({
      type: 'CART_ADD_ITEM_FAIL',
      payload: 'You must be signed in to add items to the cart.'
    });
    return; // Early return if user is not logged in
  }

  try {
    let url;
    let payload;

    if (itemType === 'subscription') {
      dispatch({
        type: CART_CLEAR_ITEMS
      });
      // Directly create the payload for a subscription
      payload = {
        id: `subscription-${id}`,
        name: `${id.charAt(0).toUpperCase()}${id.slice(1)} Subscription`,
        cost: id === 'annual' ? 70 : 7.99,
        type: itemType
      };
      console.log(`Adding to cart: ${itemType} with ID: ${id} ${payload.cost}`);

    } else {
      // For other types, construct the URL for API request
      switch (itemType) {
        case 'course':
          url = `https://api.andrewslearning.com/api/courses/${id}`;
          break;
        case 'assessment':
          url = `https://api.andrewslearning.com/api/assessments/${id}`;
          break;
        default:
          throw new Error('Invalid item type');
      }

      // Fetch the data for courses or assessments
      const { data } = await axios.get(url);
      payload = {
        id: data.id,
        name: data.name,
        image: data.image,
        description: data.description,
        cost: data.cost,
        type: itemType
      };
    }

    // Dispatch the add item action with the appropriate payload
    dispatch({
      type: CART_ADD_ITEM,
      payload: payload
    });

    console.log(`${payload.name} added to cart successfully!`);
    dispatch(updateTotalCost()); // Ensure total cost is updated

  } catch (error) {
    console.error('Error adding to cart:', error);
    dispatch({
      type: 'CART_ADD_ITEM_FAIL',
      payload: error.message
    });
  }
};
// Example Action Creator
export const resetCartSuccessMessage = () => {
  
  return {
      type: 'CART_RESET_SUCCESS_MESSAGE'
  };
};
  
export const removeFromCart = (id, type) => (dispatch, getState) => {
  console.log(`Dispatching to remove item with id: ${id} and type: ${type}`);

  dispatch({
      type: CART_REMOVE_ITEM,
      payload: { id, type },
  });

  const { cart: { cartItems } } = getState();
  

  dispatch(updateTotalCost())
  setTimeout(() => {
    const updatedState = getState().cart;
    console.log('Updated state after dispatch:', updatedState);
}, 0);
 
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

  
export const clearCart = () => (dispatch) => {
    dispatch({
      type: CART_CLEAR_ITEMS,
    })
  
    localStorage.removeItem('cartItems')
  }

  // Async action creator
  export const validateDiscountCode = (discountCode, cartItems) => async (dispatch) => {
    // Calculate total cost from cart items
    const totalCost = cartItems.reduce((total, item) => total + item.cost, 0);
  
    console.log(discountCode, "being used new totalCost is ", totalCost);
  
    try {
      dispatch({ type: VALIDATE_DISCOUNT_CODE_REQUEST });
  
      const config = {
          headers: {
              'Content-Type': 'application/json',
          },
      };
  
      const { data } = await axios.post(
          'https://api.andrewslearning.com/api/validate_discount_code',
          { discountCode, totalCost },
          config
      );
      console.log(data);
      dispatch({
          type: VALIDATE_DISCOUNT_CODE_SUCCESS,
          payload: data,
      });
      
      // Optionally, you might want to dispatch other actions based on the result
      if (data.isValid) {
          dispatch({
              type: UPDATE_TOTAL_COST,
              payload: data.newTotal,
          });
      }
    } catch (error) {
      dispatch({
          type: VALIDATE_DISCOUNT_CODE_FAIL,
          payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
    }
  };
  