import React, { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import '../App.css';

const WaveInterference = () => {
  const ref = useRef();
  const [amplitude1, setAmplitude1] = useState(50);
  const [amplitude2, setAmplitude2] = useState(50);
  const [phase, setPhase] = useState(0);
  const wavelength = 50;
  const speed = 5;
  const [combinedAmplitude, setCombinedAmplitude] = useState(0);

  useEffect(() => {
    const svg = d3.select(ref.current);
    const height = 500;
    const width = 500;
    svg.attr("width", width)
       .attr("height", height);
    const waveData = Array.from({length: width}, (_, i) => i);

    const line1 = d3.line()
      .x(d => d)
      .y((d, i) => height / 4 + amplitude1 * Math.sin(2 * Math.PI * (i / wavelength - d3.now() * speed / 1000)));

    const line2 = d3.line()
      .x(d => d)
      .y((d, i) => height / 4 + amplitude2 * Math.sin(2 * Math.PI * (i / wavelength - d3.now() * speed / 1000) + phase));

    const line3 = d3.line()
      .x(d => d)
      .y((d, i) => 3 * height / 4 +
                   (amplitude1 * Math.sin(2 * Math.PI * (i / wavelength - d3.now() * speed / 1000)) +
                    amplitude2 * Math.sin(2 * Math.PI * (i / wavelength - d3.now() * speed / 1000) + phase)));

    svg.selectAll("path").remove();

    const wave1 = svg.append("path")
      .data([waveData])
      .attr("fill", "none")
      .attr("stroke", "blue")
      .attr("stroke-width", 1);

    const wave2 = svg.append("path")
      .data([waveData])
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", 1);

    const wave3 = svg.append("path")
      .data([waveData])
      .attr("fill", "none")
      .attr("stroke", "green")
      .attr("stroke-width", 1);

    const indicator = svg.append("line")
      .attr("x1", 250)
      .attr("x2", 250)
      .attr("y1", 3 * height / 4)
      .attr("y2", 3 * height / 4)
      .attr("stroke", "white")
      .attr("stroke-width", 1);

    function animateWave() {
      const t = d3.now() * speed / 1000;
      const sin1 = amplitude1 * Math.sin(2 * Math.PI * (-t));
      const sin2 = amplitude2 * Math.sin(2 * Math.PI * (-t) + phase);
      const combinedAmplitude = sin1 + sin2;

      wave1.attr("d", line1);
      wave2.attr("d", line2);
      wave3.attr("d", line3);

      indicator.attr("y2", 3 * height / 4 + combinedAmplitude);

      setCombinedAmplitude(combinedAmplitude); 

      requestAnimationFrame(animateWave);
    }

    animateWave();
  }, [amplitude1, amplitude2, phase, wavelength, speed]);

  return (
    <div className="WaveInterference-container">
      <h1 className="WaveInterference-title">Wave Interference Simulation</h1>
      <p className="WaveInterference-description">
        Welcome to the Wave Interference Simulation. Here, you can observe and manipulate waves to understand how they interfere with each other. You can adjust the amplitude and phase of two waves and see the resulting wave formed due to interference. In physics, interference is the phenomenon in which two waves superpose to form a resultant wave of greater, lower, or the same amplitude. When the peaks of the two waves align, they constructively interfere, resulting in a wave of greater amplitude. Conversely, when a peak of one wave aligns with the trough of the other, they destructively interfere, and the waves can even completely cancel out. Enjoy experimenting with different settings!
      </p>
      <svg ref={ref}></svg>
      <div className="WaveInterference-controls">
        <div>
          <label className="WaveInterference-label">Amplitude 1:</label>
          <input type="number" value={amplitude1} onChange={(e) => setAmplitude1(+e.target.value)} />
        </div>
        <div>
          <label className="WaveInterference-label">Amplitude 2:</label>
          <input type="number" value={amplitude2} onChange={(e) => setAmplitude2(+e.target.value)} />
        </div>
        <div>
          <label className="WaveInterference-label">Phase Difference:</label>
          <input type="number" value={phase} onChange={(e) => setPhase(+e.target.value)} />
        </div>
        <div>
          <label className="WaveInterference-label">Combined Amplitude:</label>
          <p>{combinedAmplitude.toFixed(2)}</p>
        </div>
      </div>
    </div>
  );
};

export default WaveInterference;
