import React, { useState } from 'react';
import '../MathComponent.css'; // Make sure to create this file

const MathComponent = () => {
  const [num1, setNum1] = useState("");
  const [num2, setNum2] = useState("");
  const [operation, setOperation] = useState("+");
  const [result, setResult] = useState("");

  const appleImageUrl = "https://andrewslearningimage-bucket.s3.amazonaws.com/apple.png";

  const calculate = () => {
    let res;
    switch(operation) {
      case "+":
        res = Number(num1) + Number(num2);
        break;
      case "-":
        res = Number(num1) - Number(num2);
        break;
      default:
        res = "Invalid operation";
    }
    setResult(res);
  }

  const displayApples = (num) => {
    let apples = [];
    for(let i = 0; i < num; i++) {
      apples.push(<img key={i} src={appleImageUrl} alt="apple" width="50" height="50" />)
    }
    return apples;
  }

  return (
    <div id="algebra-lab" className="mathContainer">
      <h1 className="app-title">Understanding Basic Math Operations</h1>
      <p className="app-description">
        This tool helps you understand basic math operations such as addition and subtraction. 
        You can enter numbers into the two boxes below, choose an operation, and see the result. 
        The application also visually represents the numbers and the result as apples.
      </p>

      <div className="addends">
        <div>
          <p className="app-description">Enter your first number:</p>
          <input type="number" value={num1} onChange={(e) => setNum1(e.target.value)} />
          <div className="appleContainer">{displayApples(num1)}</div>
        </div>

        <select value={operation} onChange={(e) => setOperation(e.target.value)}>
          <option value="+">+</option>
          <option value="-">-</option>
        </select>

        <div>
          <p className="app-description">Enter your second number:</p>
          <input type="number" value={num2} onChange={(e) => setNum2(e.target.value)} />
          <div className="appleContainer">{displayApples(num2)}</div>
        </div>
      </div>

      <button onClick={calculate}>Calculate</button>
      <p className="app-description">Result: {result}<br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/></p>
      <div className="appleContainer">{displayApples(result)}</div>
      
    </div>
  );
}

export default MathComponent;