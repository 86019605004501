import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import api from './api';
import { useSelector } from 'react-redux';
import "./assessmentreview.css";

function AssessmentReviewPage() {
    const { assessmentResponseId } = useParams();
    const [assessmentReview, setAssessmentReview] = useState(null);
    const userInfo = useSelector(state => state.userLogin.userInfo);
    const access_token = userInfo ? userInfo.access : null;
    const location = useLocation();
    const isCatBased = location.state?.isCatBased; // Retrieve the isCatBased value
    const abilityScore = location.state?.abilityScore; // Retrieve the abilityScore value if it exists

    useEffect(() => {
        const fetchReviewData = async () => {
            try {
                const response = await api.get(`/api/assessment_response/${assessmentResponseId}`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                setAssessmentReview(response.data);
            } catch (error) {
                console.error('Error fetching assessment review data:', error);
            }
        };

        fetchReviewData();
    }, [assessmentResponseId, access_token]);

    // Check if the assessment is CAT-based and if abilityScore is provided
    const shouldDisplayAbilityScore = abilityScore !== undefined;

    if (!assessmentReview) {
        return <div>Loading review...</div>;
    }

    return (
        <div className="assessment-review-container">
            <h2 className="assessment-review-header">Assessment Review</h2>

            {/* Conditionally render the ability score message */}
            {shouldDisplayAbilityScore && (
                <center>
                    <p className="ability-score-message">
                        Your Ability Score for this CAT-based assessment is {abilityScore}. A score of approximately 2.5 is an A student, 1.5 is an average student. Try again to keep improving if you are not satisfied with your score. Happy studying!
                    </p>
                </center>
            )}

            {!assessmentReview ? (
                <div className="assessment-review-loading">Loading review...</div>
            ) : (
                <ul className="assessment-review-list">
                    {assessmentReview.questions.map((question, index) => (
                        <li key={question.id} className="assessment-review-list-item">
                            <p className="assessment-review-question">Question {index + 1}: {question.text}</p>
                            <p className="assessment-review-user-answer">Your Answer: {question.userAnswer}</p>
                            <p className="assessment-review-correct-answer">Correct Answer: {question.correctAnswer}</p>
                            <p className="assessment-review-explanation">Explanation: {question.explanation}</p>
                        </li>
                    ))}
                </ul>
            )}
            <br/>
            <br/>
            <br/>
        </div>
    );
}

export default AssessmentReviewPage;
