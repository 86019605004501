import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, getUserDetails } from '../actions/userActions';
import './Coursespage.css';

function CoursesPage() {
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState(null);

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const dispatch = useDispatch();
 // Function to get userId from local storage
  const getUserIdFromLocalStorage = () => {
  const userInfo = localStorage.getItem('userInfo');
    return userInfo ? JSON.parse(userInfo).id : null;
  };

  useEffect(() => {
    // Fetch courses
    fetch('https://api.andrewslearning.com/api/courses/')
      .then((response) => {
        if (!response.ok) {
          throw new Error('HTTP error ' + response.status);
        }
        return response.json();
      })
      .then((data) => setCourses(data))
      .catch((err) => {
        console.error('Error retrieving courses:', err);
        setError(err.toString());
      });

    // Fetch user details if not in Redux
    if (!user || !user.id) {
      const userId = getUserIdFromLocalStorage();
      if (userId) {
        dispatch(getUserDetails(userId));
      } else {
        console.error('User ID not found in local storage.');
      }
    }

  }, [dispatch, user]);

  return (
    <div>
      <center>
                  <h5>PLEASE LOGIN TO PURCHASE COURSE</h5>
      </center>
      <h1 id ="Courses"  className="course-list-title">Course List</h1>
      {error ? (
        <p>Error loading courses: {error}</p>
      ) : (
        <div className="courses-grid">
          {courses.map((course) => {
            const isPaid = course.is_paid === true || course.is_paid === "true";
            return (
              <div key={course.id} className="course-card">
                <img src={course.image} alt={course.name} className="course-image" />
                <div className="course-content">
                  <h2 className="course-title">{course.name}</h2>
                  <p className="course-description">{course.description}</p>
                 {isPaid ? (
                    user && user.paid_courses && user.paid_courses.includes(course.id) ? (
                      <Link className="course-link" to={`/courses/${course.id}`}>Enter Course</Link>
                    ) : (
                      <button
                        className="add-to-cart-button"
                        onClick={() => dispatch(addToCart(course.id,'course'))}
                      >
                        Add to Cart
                      </button>
                    )
                  ) : (
                   <Link className="course-link" to={`/courses/${course.id}`}>Enter Course</Link>
                  )}
                  
                </div>
                
        
              </div>
            );
          })}
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
           <br/>
        </div>
      )}
      <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
           <br/>
    </div>
  );
}

export default CoursesPage;
