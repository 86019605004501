import React, { useState } from 'react';
import nerdamer from 'nerdamer';
import '../App.css'; // Create this file

require('nerdamer/Algebra.js');
require('nerdamer/Calculus.js');
require('nerdamer/Solve.js');
require('nerdamer/Extra.js');

const SolveEquation = () => {
  const [equation, setEquation] = useState('');
  const [solution, setSolution] = useState('');

  const handleInputChange = (e) => {
    setEquation(e.target.value);
  }

  const handleSolve = () => {
    try {
      const sol = nerdamer.solve(equation, 'x');
      setSolution(sol.toString());
    } catch (e) {
      setSolution('Invalid equation');
    }
  }

  return (
    <div className="equation-solver-container">
      <h1 className="app-title">Equation Solver</h1>
      <p className="app-description">
        This tool can solve equations for you! You can input any valid equation into the input box below. The solver will solve for 'x' and display the solution. 
        Please ensure you are using proper syntax for your equations.
      </p>
      <div className="equation-input">
        <label className="equation-label">Enter an equation:</label>
        <input className="equation-textbox" type="text" value={equation} onChange={handleInputChange} placeholder="Enter your equation"/>
      </div>
      <button className="solve-button" onClick={handleSolve}>Solve</button>
      <p className="solution-text">Solution: {solution}</p>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  );
}

export default SolveEquation;
