import React from 'react';
import Plot from 'react-plotly.js';
import './abilitygraph.css'; // Import the new CSS file

const AbilityGraph = ({ abilityScore }) => {
    console.log('Ability score in AbilityGraph:', abilityScore);

    // Function to generate Ada's dynamic messages based on the ability score
    const getAdaAnnotation = () => {
        let message = 'Analyzing...';
        if (abilityScore > 0) {
            message = 'Great job! Your ability is above average.';
        } else if (abilityScore < 0) {
            message = 'Keep going! Ada sees potential for growth.';
        } else if (abilityScore === 0) {
            message = 'You are right on track. Ada is here to help you improve.';
        }
        return message;
    };

    // Rounded ability score
    const roundedAbilityScore = abilityScore.toFixed(2);

    const data = [
        {
            x: [abilityScore],
            y: [0],
            type: 'scatter',
            mode: 'markers',
            marker: {
                color: '#00ff00', // Neon green for digital look
                size: 14
            }
        }
    ];

    const layout = {
        title: {
            text: 'Ada’s Insight on Your Ability Level',
            font: {
                family: 'Orbitron, sans-serif',
                size: 24,
                color: '#00d4ff'
            }
        },
        xaxis: {
            title: 'Ability Score',
            titlefont: {
                family: 'Roboto Mono, monospace',
                size: 18,
                color: '#00d4ff'
            },
            showgrid: true,
            zeroline: false,
            autotick: false,
            dtick: 1,
            range: [-3, 3],
            gridcolor: '#333'
        },
        yaxis: {
            title: '',
            showticklabels: false,
            showgrid: false,
            zeroline: false,
            range: [-1, 1]
        },
        shapes: [
            {
                type: 'line',
                x0: -3,
                y0: 0,
                x1: 3,
                y1: 0,
                line: {
                    color: '#00ff00', // Neon green for digital look
                    width: 2,
                    dash: 'dot'
                }
            },
            {
                type: 'line',
                x0: abilityScore,
                y0: -1,
                x1: abilityScore,
                y1: 1,
                line: {
                    color: '#a6c6e8', // Neon pink for contrast
                    width: 2,
                    dash: 'dot'
                }
            }
        ],
        annotations: [{
            x: abilityScore,
            y: 0.5,
            xref: 'x',
            yref: 'y',
            text: getAdaAnnotation(),
            showarrow: true,
            arrowhead: 2,
            ax: 0,
            ay: -40,
            font: {
                color: '#00d4ff',
                family: 'Roboto Mono, monospace'
            }
        }],
        paper_bgcolor: 'transparent', // Transparent background
        plot_bgcolor: 'transparent', // Transparent background
        margin: {
            l: 50,
            r: 50,
            b: 50,
            t: 50,
            pad: 4
        }
    };

    return (
        <div className="graphContainer">
            <h5 className="app-title">Calculated Ability</h5>
            <div className="digital-display">
                <span className="digital-score">{roundedAbilityScore}</span>
            </div>
            <Plot data={data} layout={layout} useResizeHandler={true} style={{ width: "100%", height: "100%" }} />
            {/* Additional message from Ada below the graph */}
            <div className="ada-message">
                <p>{getAdaAnnotation()}</p>
            </div>
        </div>
    );
};

export default AbilityGraph;
