import React, { useState, useEffect } from 'react';
import p5 from 'p5';

const RefractionSketch = () => {
  const [angleIncidence, setAngleIncidence] = useState(45); // degrees
  const [indexAir, setIndexAir] = useState(1.0);
  const [indexWater, setIndexWater] = useState(1.33);
  const [start, setStart] = useState(false);
  const [reset, setReset] = useState(false);

  let sketch = function (p) {
    let wave, surfaceHeight, amplitude, wavelength, speed;

    p.setup = function () {
      p.createCanvas(p.windowWidth / 2, p.windowHeight / 2);
      p.angleMode(p.DEGREES);

      surfaceHeight = p.height / 2;
      amplitude = 20;
      wavelength = 200;
      speed = 0.05;
      wave = new Wave(p.width / 2, surfaceHeight, angleIncidence, 'red', amplitude, wavelength, speed);
    };

    p.draw = function () {
      p.background(220);

      // Draw the surface
      p.line(0, surfaceHeight, p.width, surfaceHeight);

      if (start) {
        wave.move();
        wave.display();

        if (wave.hasCrossed(surfaceHeight)) {
          wave.refract(indexAir, indexWater);
        }
      }

      if (reset) {
        wave = new Wave(p.width / 2, surfaceHeight, angleIncidence, 'red', amplitude, wavelength, speed);
        setReset(false);
      }
    };

    class Wave {
      constructor(x, y, angle, color, amplitude, wavelength, speed) {
        this.x = x;
        this.y = y;
        this.angle = angle;
        this.color = color;
        this.amplitude = amplitude;
        this.wavelength = wavelength;
        this.speed = speed;
        this.phase = 0;
      }

      move() {
        this.y += this.speed;
        this.phase += this.speed;
      }

      display() {
        p.stroke(this.color);
        for (let i = -this.wavelength; i <= this.wavelength; i++) {
          let x = this.x + this.amplitude * Math.sin((i / this.wavelength) * (2 * Math.PI) - this.phase);
          let y = this.y + i;
          p.point(x, y);
        }
      }

      hasCrossed(borderY) {
        return this.y > borderY;
      }

      refract(n1, n2) {
        this.angle = p.asin((n1 / n2) * p.sin(this.angle));
        this.color = 'blue';
      }
    }
  };

  useEffect(() => {
    let myp5 = new p5(sketch);
    return () => {
      myp5.remove();
    };
  }, [start, reset]);

  return (
    <div>
      <div>
        <label>Angle of Incidence (degrees): </label>
        <input
          type="number"
          value={angleIncidence}
          onChange={(e) => setAngleIncidence(e.target.value)}
        />
      </div>
      <div>
        <label>Refractive Index of Air: </label>
        <input
          type="number"
          value={indexAir}
          onChange={(e) => setIndexAir(e.target.value)}
        />
      </div>
      <div>
        <label>Refractive Index of Water: </label>
        <input
          type="number"
          value={indexWater}
          onChange={(e) => setIndexWater(e.target.value)}
        />
      </div>
      <button onClick={() => setStart(true)}>Start</button>
      <button onClick={() => setStart(false)}>Stop</button>
      <button onClick={() => setReset(true)}>Reset</button>
    </div>
  );
};

export default RefractionSketch;
