import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { register } from '../actions/userActions';

import FormContainer from './FormContainer';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useNavigate } from 'react-router-dom';


const Register = () => {
  const [first_name, set_first_name] = useState('');
  const [last_name, set_last_name] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords don't match");
    } else {
      dispatch(register(first_name,last_name, username, email, password));
      alert("Your account has been created! Welcome to Andrew's Leaning Center!");
      navigate('/Login#LoginForm'); // Navigate to the login page, focusing on the LoginForm
    }
  };

  return (
    <FormContainer>
      <h2>Register</h2>
      {message && <p>{message}</p>}
      <Form id="RegistrationForm" onSubmit={handleRegister}>
        <Form.Group controlId='first_name'>
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter name"
            value={first_name}
            onChange={(e) => set_first_name(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId='last_name'>
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter name"
            value={last_name}
            onChange={(e) => set_last_name(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId='username'>
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId='email'>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId='password'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId='confirmPassword'>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Group>

        <Button type='submit' variant='primary'>Register</Button>
      </Form>

      <Row className='py-3'>
        <Col>
          Have an account already?{' '}
          <Link to='/login'>
            Login
          </Link>
          <br/>
          <br/>
          <br/>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default Register;

