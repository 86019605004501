import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, updateUserProfile } from '../actions/userActions';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './Profile.css';

const Profile = () => {
  const { id } = useParams();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [subscribedToNewsletter, setSubscribedToNewsletter] = useState(false);
  const [has_active_subscription, set_has_active_subscription] = useState(false);
  const [streak, setStreak] = useState(0);
  const [questions, setQuestions] = useState(0);
  const [profilePic, setProfilePic] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const dispatch = useDispatch();

  const userDetails = useSelector(state => state.userDetails) || {};
  const { error, loading, user } = userDetails;

  const userLogin = useSelector(state => state.userLogin) || {};
  const { userInfo } = userLogin;

  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    } else {
      if (!user || !user.username) {
        dispatch(getUserDetails(id));
      } else {
        setUsername(user.username);
        setEmail(user.email);
        setSubscribedToNewsletter(user.subscribed_to_newsletter);
        set_has_active_subscription(user.has_active_subscription);
        setStreak(user.streak || 0);
        setQuestions(user.questions || 0);
        setProfilePic(`${user.Propic}`);
      }
    }
  }, [dispatch, navigate, userInfo, user, id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userUpdates = {
      id: userInfo._id,
      name: userInfo.name,
      email: email,
      username: username,
      subscribedToNewsletter: subscribedToNewsletter
    };
    await dispatch(updateUserProfile(userUpdates));
    if (selectedFile) {
      await uploadProfilePic(selectedFile);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const uploadProfilePic = async (file) => {
    const formData = new FormData();
    formData.append('profile_pic', file);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        'https://api.andrewslearning.com/api/profilepictureaddition',
        formData,
        config
      );

      setProfilePic(data.profilePic); // Assuming the response contains the updated profile picture URL
      console.log('Profile picture uploaded successfully:', data);

    } catch (error) {
      console.error('Error uploading profile picture:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <Row className="justify-content-md-center">
      <Col md={6}>
        <div className="form-container">
          <h2>User Profile</h2>
          <div className="profile-pic-placeholder">
            {profilePic ? <img src={profilePic} alt="Profile" /> : 'Profile Picture'}
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId='username'>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId='subscribeNewsletter'>
              <Form.Check
                type='checkbox'
                label='Subscribe to Newsletter'
                checked={subscribedToNewsletter}
                onChange={(e) => setSubscribedToNewsletter(e.target.checked)}
              />
            </Form.Group>

            <Form.Group controlId='subscription'>
              <Form.Check
                type='checkbox'
                label='Subscribed?'
                checked={has_active_subscription}
                onChange={(e) => set_has_active_subscription(e.target.checked)}
              />
            </Form.Group>

            <Form.Group controlId='profilePic'>
              <Form.Label>Change Profile Picture</Form.Label>
              <Form.Control
                type="file"
                onChange={handleFileChange}
              />
            </Form.Group>

            <div className="profile-info">
              <p><strong>Streak:</strong> {streak} days</p>
              <p><strong>Questions Answered:</strong> {questions}</p>
            </div>

            <Button type='submit' variant='primary'>Update</Button>
          </Form>
          <Link to="/change-password" className="change-password-link">Change Password</Link>
        </div>
      </Col>
    </Row>
  );
};

export default Profile;
