import React, { useState, useEffect } from 'react';
import './MoleculeViewer.css';

const MoleculeViewer = () => {
    const queryParams = new URLSearchParams(window.location.search);

    const [isPhone, setIsPhone] = useState(window.innerWidth < 600);
    const [height, setHeight] = useState(queryParams.get('height') || (isPhone ? '300px' : '800px'));
    const [width, setWidth] = useState(queryParams.get('width') || (isPhone ? '300px' : '800px'));
    const [pdb, setPdb] = useState(queryParams.get('pdb') || '1a3n'); // Default PDB code
    const [cid, setCid] = useState(queryParams.get('cid') || '');
    const [href, setHref] = useState(queryParams.get('href') || '');
    const [element, setElement] = useState(queryParams.get('element') || '');
    const [type, setType] = useState(queryParams.get('type') || 'pdb');
    const [backgroundColor, setBackgroundColor] = useState(queryParams.get('backgroundColor') || 'black');
    const [backgroundAlpha, setBackgroundAlpha] = useState(queryParams.get('backgroundAlpha') || '1.0');
    const [select, setSelect] = useState(queryParams.get('select') || '');
    const [style, setStyle] = useState(queryParams.get('style') || 'cartoon'); // Default style
    const [surface, setSurface] = useState(queryParams.get('surface') || '');
    const [labelRes, setLabelRes] = useState(queryParams.get('labelRes') || '');
    const [zoomTo, setZoomTo] = useState(queryParams.get('zoomTo') || '');
    const [spin, setSpin] = useState(queryParams.get('spin') || '');
    const [ui, setUi] = useState(queryParams.get('ui') || 'true');

    useEffect(() => {
        const handleResize = () => {
            setIsPhone(window.innerWidth < 600);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (queryParams.get('height')) {
            setHeight(queryParams.get('height') + 'px');
        } else {
            setHeight(isPhone ? '300px' : '800px');
        }

        if (queryParams.get('width')) {
            setWidth(queryParams.get('width') + 'px');
        } else {
            setWidth(isPhone ? '300px' : '800px');
        }
    }, [isPhone, queryParams]);

    const handleHeightChange = (e) => {
        setHeight(e.target.value + 'px');
    };

    const handleWidthChange = (e) => {
        setWidth(e.target.value + 'px');
    };

    const handlePdbChange = (e) => {
        setPdb(e.target.value);
    };

    const handleCidChange = (e) => {
        setCid(e.target.value);
    };

    const handleHrefChange = (e) => {
        setHref(e.target.value);
    };

    const handleElementChange = (e) => {
        setElement(e.target.value);
    };

    const handleTypeChange = (e) => {
        setType(e.target.value);
    };

    const handleBackgroundColorChange = (e) => {
        setBackgroundColor(e.target.value);
    };

    const handleBackgroundAlphaChange = (e) => {
        setBackgroundAlpha(e.target.value);
    };

    const handleSelectChange = (e) => {
        setSelect(e.target.value);
    };

    const handleStyleChange = (e) => {
        setStyle(e.target.value);
    };

    const handleSurfaceChange = (e) => {
        setSurface(e.target.value);
    };

    const handleLabelResChange = (e) => {
        setLabelRes(e.target.value);
    };

    const handleZoomToChange = (e) => {
        setZoomTo(e.target.value);
    };

    const handleSpinChange = (e) => {
        setSpin(e.target.value);
    };

    const handleUiChange = (e) => {
        setUi(e.target.value);
    };

    const handleApplyChanges = () => {
        const params = new URLSearchParams({
            height: parseInt(height),
            width: parseInt(width),
            pdb,
            cid,
            href,
            element,
            type,
            backgroundColor,
            backgroundAlpha,
            select,
            style,
            surface,
            labelRes,
            zoomTo,
            spin,
            ui,
        });
        window.location.search = params.toString();
    };

    const viewerStyle = {
        height,
        width,
        position: 'relative'
    };

    return (
        <div>
            <center>
                <div className="text-container">
                    <h2>Welcome to the MoleculeViewer</h2>
                    <p>
                        This interactive tool allows you to explore the molecular structure of various compounds. 
                        Utilizing the 3Dmol.js library, it renders a detailed 3D model based on the PDB code provided.
                    </p>
                    <p>
                        <strong>How to use:</strong> Use your mouse to rotate the model for a full view. 
                        Zoom in or out to see the structure in more detail. 
                        You can also click on individual atoms to learn more about their properties.
                    </p>
                </div>

                <div className="controls-container">
                    <label>
                        Height (px): 
                        <input type="number" value={parseInt(height)} onChange={handleHeightChange} />
                    </label>
                    <label>
                        Width (px): 
                        <input type="number" value={parseInt(width)} onChange={handleWidthChange} />
                    </label>
                    <label>
                        PDB Code: 
                        <input type="text" value={pdb} onChange={handlePdbChange} />
                    </label>
                    <label>
                        CID: 
                        <input type="text" value={cid} onChange={handleCidChange} />
                    </label>
                    <label>
                        HREF: 
                        <input type="text" value={href} onChange={handleHrefChange} />
                    </label>
                    <label>
                        Element ID: 
                        <input type="text" value={element} onChange={handleElementChange} />
                    </label>
                    <label>
                        File Type: 
                        <input type="text" value={type} onChange={handleTypeChange} />
                    </label>
                    <label>
                        Background Color: 
                        <input type="text" value={backgroundColor} onChange={handleBackgroundColorChange} />
                    </label>
                    <label>
                        Background Alpha: 
                        <input type="text" value={backgroundAlpha} onChange={handleBackgroundAlphaChange} />
                    </label>
                    <label>
                        Select: 
                        <input type="text" value={select} onChange={handleSelectChange} />
                    </label>
                    <label>
                        Style: 
                        <input type="text" value={style} onChange={handleStyleChange} />
                    </label>
                    <label>
                        Surface: 
                        <input type="text" value={surface} onChange={handleSurfaceChange} />
                    </label>
                    <label>
                        Label Res: 
                        <input type="text" value={labelRes} onChange={handleLabelResChange} />
                    </label>
                    <label>
                        Zoom To: 
                        <input type="text" value={zoomTo} onChange={handleZoomToChange} />
                    </label>
                    <label>
                        Spin: 
                        <input type="text" value={spin} onChange={handleSpinChange} />
                    </label>
                    <label>
                        UI: 
                        <input type="text" value={ui} onChange={handleUiChange} />
                    </label>
                    <button onClick={handleApplyChanges}>
                        Apply Changes
                    </button>
                </div>

                <div style={viewerStyle} className='viewer_3Dmoljs' 
                     data-pdb={pdb || undefined}
                     data-cid={cid || undefined}
                     data-href={href || undefined}
                     data-element={element || undefined}
                     data-type={type || undefined}
                     data-backgroundcolor={backgroundColor || undefined}
                     data-backgroundalpha={backgroundAlpha || undefined}
                     data-select={select || undefined}
                     data-style={style || undefined}
                     data-surface={surface || undefined}
                     data-labelres={labelRes || undefined}
                     data-zoomto={zoomTo || undefined}
                     data-spin={spin || undefined}
                     data-ui={ui || undefined}>
                </div>
            </center>
        </div>
    );
};

export default MoleculeViewer;
