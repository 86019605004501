import React, { useEffect, useState } from 'react';
import p5 from 'p5';

const WaveSketch = () => {
  const [amplitude, setAmplitude] = useState(100);
  const [wavelength, setWavelength] = useState(100);
  const [speed, setSpeed] = useState(1);
  const [frequency, setFrequency] = useState(1);

  let sketch = function (p) {
    let angle = 0;

    p.setup = function () {
      p.createCanvas(p.windowWidth, p.windowHeight);
      p.textAlign(p.CENTER);
    };

    p.draw = function () {
      p.background(0);

      p.beginShape();
      for (let x = 0; x < p.width; x++) {
        let y = amplitude * p.sin(2 * p.PI * ((x - angle * speed) / wavelength));
        p.vertex(x, y + p.height / 2);
      }
      p.endShape();

      angle += frequency;

      // Display the wave equation
      p.fill(255);
      p.textSize(24);
      p.text(`y(x, t) = ${amplitude} * sin((2π/${wavelength})x - (2π${frequency > 0 ? `*${frequency}` : ''})t)`, p.width / 2, 50);
    };
  };

  useEffect(() => {
    let myp5 = new p5(sketch);
    return () => { myp5.remove() };
  }, [amplitude, wavelength, speed, frequency]);

  return (
    <div>
      <div>
        <label>Amplitude:</label>
        <input type="number" value={amplitude} onChange={(e) => setAmplitude(e.target.value)} />
      </div>
      <div>
        <label>Wavelength:</label>
        <input type="number" value={wavelength} onChange={(e) => setWavelength(e.target.value)} />
      </div>
      <div>
        <label>Speed:</label>
        <input type="number" value={speed} onChange={(e) => setSpeed(e.target.value)} />
      </div>
      <div>
        <label>Frequency:</label>
        <input type="number" value={frequency} onChange={(e) => setFrequency(e.target.value)} />
      </div>
    </div>
  );
};

export default WaveSketch;
