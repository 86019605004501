import React, { useEffect, useState } from 'react';
import p5 from 'p5';

const MomentumSketch = () => {
  const [velocity1, setVelocity1] = useState(3);
  const [velocity2, setVelocity2] = useState(-2);
  const [mass1, setMass1] = useState(2);
  const [mass2, setMass2] = useState(1);
  const [playing, setPlaying] = useState(false);
  const [ball1Values, setBall1Values] = useState({ mass: mass1, velocity: velocity1 });
  const [ball2Values, setBall2Values] = useState({ mass: mass2, velocity: velocity2 });

  useEffect(() => {
    setBall1Values({ mass: mass1, velocity: velocity1 });
    setBall2Values({ mass: mass2, velocity: velocity2 });
  }, [mass1, mass2, velocity1, velocity2]);

  const sketch = (p) => {
    let ball1, ball2;

    class Ball {
      constructor(m, x, v) {
        this.mass = m;
        this.position = x;
        this.velocity = v;
        this.radius = p.sqrt(m) * 10;
      }

      update() {
        this.position += this.velocity;
      }

      draw() {
        p.ellipse(this.position, p.height / 2, this.radius * 2, this.radius * 2);
      }

      collide(other) {
        let sumM = this.mass + other.mass;
        let newV = (this.mass - other.mass) / sumM * this.velocity;
        newV += (2 * other.mass / sumM) * other.velocity;
        return newV;
      }
    }

    p.setup = () => {
      p.createCanvas(p.windowWidth, p.windowHeight);
      ball1 = new Ball(mass1, 100, velocity1);
      ball2 = new Ball(mass2, p.width - 100, velocity2);
    };

    p.draw = () => {
      p.background(220);

      if (playing) {
        ball1.update();
        ball2.update();

        if (Math.abs(ball1.position - ball2.position) < ball1.radius + ball2.radius) {
          const v1 = ball1.collide(ball2);
          const v2 = ball2.collide(ball1);
          ball1.velocity = v1;
          ball2.velocity = v2;
          setBall1Values({ mass: ball1.mass, velocity: ball1.velocity });
          setBall2Values({ mass: ball2.mass, velocity: ball2.velocity });
        }
      }

      ball1.draw();
      ball2.draw();
    };
  };

  useEffect(() => {
    const myp5 = new p5(sketch);
    return () => myp5.remove();
  }, [velocity1, velocity2, mass1, mass2, playing]);

  return (
    <div className="app-container">
      <h1 className="app-title">Momentum and its Conservation</h1>
      <p className="app-description">
        In physics, momentum is a measure of the motion of an object. It is calculated by multiplying the mass of the object by its velocity. The formula is:
      </p>
      <p className="app-formula"><strong>Momentum = Mass * Velocity</strong></p>
      <p className="app-description">
        The conservation of momentum is a fundamental concept in physics. It states that the total momentum of a closed system (one that does not interact with external forces) remains constant. This is true whether the objects within the system are at rest or in motion.
      </p>
      <p className="app-description">
        This application helps students understand these concepts by allowing them to manipulate the mass and velocity of two balls and observe how these changes affect the system's momentum. By observing the interaction (collision) of the two balls, users can see the conservation of momentum in action.
      </p>
      <button type="button" className="app-button" onClick={() => setPlaying(!playing)}>{playing ? "Pause" : "Play"}</button>
      <div>
        <p>Ball 1</p>
        <p>Mass: {ball1Values.mass}</p>
        <p>Velocity: {ball1Values.velocity}</p>
        <p>Momentum: {ball1Values.mass * ball1Values.velocity}</p>
        <input type="range" min="1" max="20" value={mass1} onChange={e => setMass1(+e.target.value)} />
        <input type="range" min="-10" max="10" value={velocity1} onChange={e => setVelocity1(+e.target.value)} />
      </div>
      <div>
        <p>Ball 2</p>
        <p>Mass: {ball2Values.mass}</p>
        <p>Velocity: {ball2Values.velocity}</p>
        <p>Momentum: {ball2Values.mass * ball2Values.velocity}</p>
        <input type="range" min="1" max="20" value={mass2} onChange={e => setMass2(+e.target.value)} />
        <input type="range" min="-10" max="10" value={velocity2} onChange={e => setVelocity2(+e.target.value)} />
      </div>
    </div>
  );
};

export default MomentumSketch;
