import React from 'react';
import './MathKeyboard.css'; // Create this CSS file

const MathKeyboard = ({ onKeyPress }) => {
    const keys = [
        '(', ')', '|', '[', ']', '=', 
        'x', '7', '8', '9', '/',
        'y', '4', '5', '6', '*',
        'z', '1', '2', '3', '-',
        'abc', '0', '.', '%', '+',
        '√', '∛', '≤', '≥', '⟂',
        'ln', 'e', 'θ', '°', 'π',
        'sin', 'cos', 'tan', 'log', '!',
        'x/y', 'f(x)', '{', '}', '<<',
    ];

    return (
        <div className="keyboard">
            {keys.map(key => (
                <button key={key} className="key" onClick={() => onKeyPress(key)}>
                    {key}
                </button>
            ))}
        </div>
    );
};

export default MathKeyboard;
