import React, { useState } from 'react';
import '../PeriodicTable.css'; // Add some styles in this file

// Define a basic elements array


  const PeriodicTable = () => {
    const elements = [
      { atomicNumber: 1, symbol: 'H', name: 'Hydrogen', atomicMass: '1.008' },
      { atomicNumber: 2, symbol: 'He', name: 'Helium', atomicMass: '4.0026' },
      { atomicNumber: 3, symbol: 'Li', name: 'Lithium', atomicMass: '6.94' },
      { atomicNumber: 4, symbol: 'Be', name: 'Beryllium', atomicMass: '9.0122' },
      { atomicNumber: 5, symbol: 'B', name: 'Boron', atomicMass: '10.81' },
      { atomicNumber: 6, symbol: 'C', name: 'Carbon', atomicMass: '12.011' },
      { atomicNumber: 7, symbol: 'N', name: 'Nitrogen', atomicMass: '14.007' },
      { atomicNumber: 8, symbol: 'O', name: 'Oxygen', atomicMass: '15.999' },
      { atomicNumber: 9, symbol: 'F', name: 'Fluorine', atomicMass: '18.998' },
      { atomicNumber: 10, symbol: 'Ne', name: 'Neon', atomicMass: '20.180' },
      { atomicNumber: 11, symbol: 'Na', name: 'Sodium', atomicMass: '22.990' },
      { atomicNumber: 12, symbol: 'Mg', name: 'Magnesium', atomicMass: '24.305' },
      { atomicNumber: 13, symbol: 'Al', name: 'Aluminium', atomicMass: '26.982' },
      { atomicNumber: 14, symbol: 'Si', name: 'Silicon', atomicMass: '28.085' },
      { atomicNumber: 15, symbol: 'P', name: 'Phosphorus', atomicMass: '30.974' },
      { atomicNumber: 16, symbol: 'S', name: 'Sulfur', atomicMass: '32.06' },
      { atomicNumber: 17, symbol: 'Cl', name: 'Chlorine', atomicMass: '35.45' },
      { atomicNumber: 18, symbol: 'Ar', name: 'Argon', atomicMass: '39.948' },
      { atomicNumber: 19, symbol: 'K', name: 'Potassium', atomicMass: '39.098' },
      { atomicNumber: 20, symbol: 'Ca', name: 'Calcium', atomicMass: '40.078' },
      { atomicNumber: 21, symbol: 'Sc', name: 'Scandium', atomicMass: '44.956' },
      { atomicNumber: 22, symbol: 'Ti', name: 'Titanium', atomicMass: '47.867' },
      { atomicNumber: 23, symbol: 'V', name: 'Vanadium', atomicMass: '50.942' },
      { atomicNumber: 24, symbol: 'Cr', name: 'Chromium', atomicMass: '51.996' },
      { atomicNumber: 25, symbol: 'Mn', name: 'Manganese', atomicMass: '54.938' },
      { atomicNumber: 26, symbol: 'Fe', name: 'Iron', atomicMass: '55.845' },
      { atomicNumber: 27, symbol: 'Co', name: 'Cobalt', atomicMass: '58.933' },
      { atomicNumber: 28, symbol: 'Ni', name: 'Nickel', atomicMass: '58.693' },
      { atomicNumber: 29, symbol: 'Cu', name: 'Copper', atomicMass: '63.546' },
      { atomicNumber: 30, symbol: 'Zn', name: 'Zinc', atomicMass: '65.38' },
      { atomicNumber: 31, symbol: 'Ga', name: 'Gallium', atomicMass: '69.72' },
      { atomicNumber: 32, symbol: 'Ge', name: 'Germanium', atomicMass: '72.63' },
      { atomicNumber: 33, symbol: 'As', name: 'Arsenic', atomicMass: '74.922' },
      { atomicNumber: 34, symbol: 'Se', name: 'Selenium', atomicMass: '78.971' },
      { atomicNumber: 35, symbol: 'Br', name: 'Bromine', atomicMass: '79.904' },
      { atomicNumber: 36, symbol: 'Kr', name: 'Krypton', atomicMass: '83.798' },
      { atomicNumber: 37, symbol: 'Rb', name: 'Rubidium', atomicMass: '85.468' },
      { atomicNumber: 38, symbol: 'Sr', name: 'Strontium', atomicMass: '87.62' },
      { atomicNumber: 39, symbol: 'Y', name: 'Yttrium', atomicMass: '88.906' },
      { atomicNumber: 40, symbol: 'Zr', name: 'Zirconium', atomicMass: '91.224' },
      { atomicNumber: 41, symbol: 'Nb', name: 'Niobium', atomicMass: '92.906' },
      { atomicNumber: 42, symbol: 'Mo', name: 'Molybdenum', atomicMass: '95.95' },
      { atomicNumber: 43, symbol: 'Tc', name: 'Technetium', atomicMass: '(98)' },
      { atomicNumber: 44, symbol: 'Ru', name: 'Ruthenium', atomicMass: '101.07' },
      { atomicNumber: 45, symbol: 'Rh', name: 'Rhodium', atomicMass: '102.91' },  
      { atomicNumber: 46, symbol: 'Pd', name: 'Palladium', atomicMass: '106.42' },
      { atomicNumber: 47, symbol: 'Ag', name: 'Silver', atomicMass: '107.87' },
      { atomicNumber: 48, symbol: 'Cd', name: 'Cadmium', atomicMass: '112.41' },
      { atomicNumber: 49, symbol: 'In', name: 'Indium', atomicMass: '114.82' },
      { atomicNumber: 50, symbol: 'Sn', name: 'Tin', atomicMass: '118.71' },
      { atomicNumber: 51, symbol: 'Sb', name: 'Antimony', atomicMass: '121.76' },
      { atomicNumber: 52, symbol: 'Te', name: 'Tellurium', atomicMass: '127.6' },
      { atomicNumber: 53, symbol: 'I', name: 'Iodine', atomicMass: '126.9' },
      { atomicNumber: 54, symbol: 'Xe', name: 'Xenon', atomicMass: '131.29' },
      { atomicNumber: 55, symbol: 'Cs', name: 'Cesium', atomicMass: '132.91' },
      { atomicNumber: 56, symbol: 'Ba', name: 'Barium', atomicMass: '137.33' },
      { atomicNumber: 57, symbol: 'La', name: 'Lanthanum', atomicMass: '138.91' },
      { atomicNumber: 58, symbol: 'Ce', name: 'Cerium', atomicMass: '140.12' },
      { atomicNumber: 59, symbol: 'Pr', name: 'Praseodymium', atomicMass: '140.91' },
      { atomicNumber: 60, symbol: 'Nd', name: 'Neodymium', atomicMass: '144.24' },  
      { atomicNumber: 61, symbol: 'Pm', name: 'Promethium', atomicMass: '(145)' },
      { atomicNumber: 62, symbol: 'Sm', name: 'Samarium', atomicMass: '150.36' },
      { atomicNumber: 63, symbol: 'Eu', name: 'Europium', atomicMass: '151.96' },
      { atomicNumber: 64, symbol: 'Gd', name: 'Gadolinium', atomicMass: '157.25' },
      { atomicNumber: 65, symbol: 'Tb', name: 'Terbium', atomicMass: '158.93' },
      { atomicNumber: 66, symbol: 'Dy', name: 'Dysprosium', atomicMass: '162.50' },
      { atomicNumber: 67, symbol: 'Ho', name: 'Holmium', atomicMass: '164.93' },
      { atomicNumber: 68, symbol: 'Er', name: 'Erbium', atomicMass: '167.26' },
      { atomicNumber: 69, symbol: 'Tm', name: 'Thulium', atomicMass: '168.93' },
      { atomicNumber: 70, symbol: 'Yb', name: 'Ytterbium', atomicMass: '173.04' },
      { atomicNumber: 71, symbol: 'Lu', name: 'Lutetium', atomicMass: '174.97' },
      { atomicNumber: 72, symbol: 'Hf', name: 'Hafnium', atomicMass: '178.49' },
      { atomicNumber: 73, symbol: 'Ta', name: 'Tantalum', atomicMass: '180.95' },
      { atomicNumber: 74, symbol: 'W', name: 'Tungsten', atomicMass: '183.84' },
      { atomicNumber: 75, symbol: 'Re', name: 'Rhenium', atomicMass: '186.21' },
      { atomicNumber: 76, symbol: 'Os', name: 'Osmium', atomicMass: '190.23' },
      { atomicNumber: 77, symbol: 'Ir', name: 'Iridium', atomicMass: '192.22' },
      { atomicNumber: 78, symbol: 'Pt', name: 'Platinum', atomicMass: '195.08' },
      { atomicNumber: 79, symbol: 'Au', name: 'Gold', atomicMass: '196.97' },
      { atomicNumber: 80, symbol: 'Hg', name: 'Mercury', atomicMass: '200.59' },
      { atomicNumber: 81, symbol: 'Tl', name: 'Thallium', atomicMass: '204.38' },
      { atomicNumber: 82, symbol: 'Pb', name: 'Lead', atomicMass: '207.2' },
      { atomicNumber: 83, symbol: 'Bi', name: 'Bismuth', atomicMass: '208.98' },
      { atomicNumber: 84, symbol: 'Po', name: 'Polonium', atomicMass: '(209)' },
      { atomicNumber: 85, symbol: 'At', name: 'Astatine', atomicMass: '(210)' },
      { atomicNumber: 86, symbol: 'Rn', name: 'Radon', atomicMass: '(222)' },
      { atomicNumber: 87, symbol: 'Fr', name: 'Francium', atomicMass: '(223)' },
      { atomicNumber: 88, symbol: 'Ra', name: 'Radium', atomicMass: '(226)' },
      { atomicNumber: 89, symbol: 'Ac', name: 'Actinium', atomicMass: '(227)' },
      { atomicNumber: 90, symbol: 'Th', name: 'Thorium', atomicMass: '232.04' },
      { atomicNumber: 91, symbol: 'Pa', name: 'Protactinium', atomicMass: '231.04' },
      { atomicNumber: 92, symbol: 'U', name: 'Uranium', atomicMass: '238.03' },
      { atomicNumber: 93, symbol: 'Np', name: 'Neptunium', atomicMass: '(237)' },
      { atomicNumber: 94, symbol: 'Pu', name: 'Plutonium', atomicMass: '(244)' },
      { atomicNumber: 95, symbol: 'Am', name: 'Americium', atomicMass: '(243)' },
      { atomicNumber: 96, symbol: 'Cm', name: 'Curium', atomicMass: '(247)' },
      { atomicNumber: 97, symbol: 'Bk', name: 'Berkelium', atomicMass: '(247)' },
      { atomicNumber: 98, symbol: 'Cf', name: 'Californium', atomicMass: '(251)' },
      { atomicNumber: 99, symbol: 'Es', name: 'Einsteinium', atomicMass: '(252)' },
      { atomicNumber: 100, symbol: 'Fm', name: 'Fermium', atomicMass: '(257)' },
      { atomicNumber: 101, symbol: 'Md', name: 'Mendelevium', atomicMass: '(258)' },
      { atomicNumber: 102, symbol: 'No', name: 'Nobelium', atomicMass: '(259)' },
      { atomicNumber: 103, symbol: 'Lr', name: 'Lawrencium', atomicMass: '(266)' },
      { atomicNumber: 104, symbol: 'Rf', name: 'Rutherfordium', atomicMass: '(267)' },
      { atomicNumber: 105, symbol: 'Db', name: 'Dubnium', atomicMass: '(270)' },
      { atomicNumber: 106, symbol: 'Sg', name: 'Seaborgium', atomicMass: '(271)' },
      { atomicNumber: 107, symbol: 'Bh', name: 'Bohrium', atomicMass: '(270)' },
      { atomicNumber: 108, symbol: 'Hs', name: 'Hassium', atomicMass: '(277)' },
      { atomicNumber: 109, symbol: 'Mt', name: 'Meitnerium', atomicMass: '(276)' },
      { atomicNumber: 110, symbol: 'Ds', name: 'Darmstadtium', atomicMass: '(281)' },
      { atomicNumber: 111, symbol: 'Rg', name: 'Roentgenium', atomicMass: '(282)' },
      { atomicNumber: 112, symbol: 'Cn', name: 'Copernicium', atomicMass: '(285)' },
      { atomicNumber: 113, symbol: 'Nh', name: 'Nihonium', atomicMass: '(284)' },
      { atomicNumber: 114, symbol: 'Fl', name: 'Flerovium', atomicMass: '(289)' },
      { atomicNumber: 115, symbol: 'Mc', name: 'Moscovium', atomicMass: '(288)' },
      { atomicNumber: 116, symbol: 'Lv', name: 'Livermorium', atomicMass: '(293)' },
      { atomicNumber: 117, symbol: 'Ts', name: 'Tennessine', atomicMass: '(294)' },
      { atomicNumber: 118, symbol: 'Og', name: 'Oganesson', atomicMass: '(294)' },
    
    ]; // Include the elements data here
  
    const [searchTerm, setSearchTerm] = useState('');
  
    const filteredElements = elements.filter(
      (element) =>
        element.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        element.symbol.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    return (
      <div className="periodic-table">
        <h1>Periodic Table</h1>
        
        <input
          type="text"
          placeholder="Search..."
          onChange={event => setSearchTerm(event.target.value)}
        />
  
        <div className="element-grid">
          {filteredElements.map((element, index) => (
            <div className="element" key={index} style={{gridArea: `cell${element.atomicNumber}`}}>
              <h4>{element.symbol}</h4>
              <p>{element.name}</p>
              <p>{element.atomicMass}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default PeriodicTable;