import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart } from '../actions/userActions';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from "./CheckoutForm";
import './Checkouttwo.css';



// test = pk_test_51OGPqzFg0IM8tPHkjbRRtmptpWiz6olT8mfAZMAk4gLR2xQegBR6k2rXdxSoKJZLa3YnAB2YltjnUpopkjSD98Dj00ru36okS0
// live = pk_live_51OGPqzFg0IM8tPHkISRnJ4WyaCkWlM3A775ipg5gqUxcQZ05tN8Bh7FJLLDRf3pqf9nk2TFGNJBD18m5qPbmcY7x00eA46JtNp
const stripePromise = loadStripe('pk_live_51OGPqzFg0IM8tPHkISRnJ4WyaCkWlM3A775ipg5gqUxcQZ05tN8Bh7FJLLDRf3pqf9nk2TFGNJBD18m5qPbmcY7x00eA46JtNp');

export default function Checkout() {
  const cartItems = useSelector(state => state.cart.cartItems);
  const [clientSecret, setClientSecret] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const dispatch = useDispatch();
  
  // Retrieve total cost from local storage or default to '0'
const totalCost = useSelector((state) => state.cart.totalCost)
const itemnames = useSelector((state) => state.cart.cartItems)
  const getUserIdFromLocalStorage = () => {
    const userInfo = localStorage.getItem('userInfo');
    
    return userInfo ? JSON.parse(userInfo).id : null;
  };

  const handleRemoveFromCart = (id, type) => {
    dispatch(removeFromCart(id, type));
  };


  const formattedTotalCost = totalCost ? Number(totalCost).toFixed(2) : "0.00";

  useEffect(() => {

    const userId = getUserIdFromLocalStorage();
    const items = cartItems.map(item => ({ id: item.id, type: item.type }));
    console.log(items);
    if (userId) {
      fetch("https://api.andrewslearning.com/api/create-payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ totalCost, userId, items, discountCode }),
      })
      .then(res => res.json())
      .then(data => setClientSecret(data.clientSecret))
      .catch(error => console.error('Error creating payment intent:', error));
    } else {
      console.error('User ID not found in local storage.');
    }
  }, [cartItems, discountAmount]); // Dependencies for useEffect

  const options = {
    clientSecret,
    appearance: { theme: 'stripe' },
  };

  return (
    <div id="Cartincheckout" >
       <div className='checkoutcartContainer'>
        <br/>
        <br/>
        <div className='cartstuff'>
            <h2 className='Cartitems'>Cart Items</h2>
            {cartItems.length === 0 ? (
                <p>Your cart is empty</p>
            ) : (
                <ul>
                    {cartItems.map(item => (
                        <li className='Cartite' key={item.id}>
                            <div>
                                <h3 className="Cartitename">{item.name}</h3>
                                
                                
                                {/* Rest of your item markup */}
                            </div>
                        </li>
                    ))}
                </ul>
            )}
            <br/>
        </div>
    
      </div>
      <br/>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      )}
        <div className="cart-total2">
        
        <strong className="total">Total:</strong> 
        <center><span>${formattedTotalCost}</span></center>
       
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  );
}

{/* const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const cartItems = useSelector(state => state.cart.cartItems);
  const totalCost = cartItems.reduce((total, item) => total + parseFloat(item.cost), 0).toFixed(2);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const { data: { clientSecret } } = await axios.post('api/create-payment-intent', {
      totalCost,
    });

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (result.error) {
      console.error('[error]', result.error);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        console.log('Payment successful!');
        dispatch(clearCart());
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="checkout-form">
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <button type="submit" disabled={!stripe} className="pay-button">
        Pay ${totalCost}
      </button>
    </form>
  );
};

function Cart() {
  const cartItems = useSelector(state => state.cart.cartItems);
  const dispatch = useDispatch();
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const handleRemoveFromCart = (id, type) => {
    dispatch(removeFromCart(id, type));
  };

  const totalCost = cartItems.reduce((total, item) => total + parseFloat(item.cost), 0).toFixed(2);

  return (
    <Elements stripe={stripePromise}>
      <div className="cart-container">
        <h2 className="cart-header">Your Cart</h2>
        {paymentSuccess ? (
          <div className="payment-success-message">
            Payment was successful. Thank you!
          </div>
        ) : cartItems.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          <ul className="cart-list">
            {cartItems.map((item) => (
              <li key={item.id} className="cart-item">
                <span>{item.name}</span>
                <span>${item.cost}</span>
                <button onClick={() => handleRemoveFromCart(item.id, item.type)} className="remove-button">
                  Remove
                </button>
              </li>
            ))}
          </ul>
        )}
        {cartItems.length > 0 && !paymentSuccess && (
          <div className="payment-section">
            <div className="cart-total">
              <span><strong>Total:</strong></span>
              <span>${totalCost}</span>
            </div>
            <CheckoutForm />
          </div>
        )}
      </div>
    </Elements>
  );
}

export default Cart; */}
