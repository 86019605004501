import React, { useEffect, useState } from 'react';
import { initThreeJS } from './Bohr_model_threeSetup';
import './PeriodicTable.css';
import './bohrstyles.css';
import useSound from './Sound';

const ThreeComponent = () => {
  const [atomicNumber, setAtomicNumber] = useState(1);
  const [atomicMass, setAtomicMass] = useState(1);
  const [atomicSymbol, setAtomicSymbol] = useState("H");
  const [atomicName, setAtomicName] = useState("Hydrogen");
  const [atomicOrbital, setAtomicOrbital] = useState("1s1");
  const [electronCount, setElectronCount] = useState(1); // Track the number of electrons
  const playClickSound = useSound('/ui-click-43196.mp3'); // Local file path

  useEffect(() => {
    // Initialize Three.js with the current number of electrons
    initThreeJS('three-container', atomicNumber, atomicMass, electronCount);
    // Cleanup on component unmount
    return () => {
      const container = document.getElementById('three-container');
      if (container) {
        container.innerHTML = ''; // Clear Three.js renderer on unmount
      }
    };
  }, [atomicNumber, atomicMass, electronCount]); // Reinitialize Three.js when atomic number, mass, or electron count changes

  const findElementByAtomicNumber = (number) => {
    return elements.find(element => element.number === number);
  };

  const handleElementClick = (number, mass, symbol, name, orbital) => {
    setAtomicSymbol(symbol);
    setAtomicNumber(number);
    setAtomicMass(mass);
    setAtomicName(name);
    setAtomicOrbital(orbital);
    setElectronCount(number); // Reset electron count to match atomic number
    playClickSound();
  };

  const handleIonizeClick = () => {
    if (electronCount > 0) {
      const newElectronCount = electronCount - 1;
      setElectronCount(newElectronCount);

      // Update orbital or other properties if needed
      const newOrbital = calculateOrbital(atomicNumber, newElectronCount);
      setAtomicOrbital(newOrbital);

      // Reinitialize Three.js with updated electron count
      initThreeJS('three-container', atomicNumber, atomicMass, newElectronCount);
    }
  };

  const handleAddElectronClick = () => {
    const newElectronCount = electronCount + 1;
    setElectronCount(newElectronCount);

    // Update orbital or other properties if needed
    const newOrbital = calculateOrbital(atomicNumber, newElectronCount);
    setAtomicOrbital(newOrbital);

    // Reinitialize Three.js with updated electron count
    initThreeJS('three-container', atomicNumber, atomicMass, newElectronCount);
  };

  const calculateOrbital = (atomicNumber, electrons) => {
    const charge = atomicNumber - electrons;
    const orbitals = [
      { level: '1s', capacity: 2 },
      { level: '2s', capacity: 2 },
      { level: '2p', capacity: 6 },
      { level: '3s', capacity: 2 },
      { level: '3p', capacity: 6 },
      { level: '4s', capacity: 2 },
      { level: '3d', capacity: 10 },
      { level: '4p', capacity: 6 },
      { level: '5s', capacity: 2 },
      { level: '4d', capacity: 10 },
      { level: '5p', capacity: 6 },
      { level: '6s', capacity: 2 },
      { level: '4f', capacity: 14 },
      { level: '5d', capacity: 10 },
      { level: '6p', capacity: 6 },
      { level: '7s', capacity: 2 },
      { level: '5f', capacity: 14 },
      { level: '6d', capacity: 10 },
      { level: '7p', capacity: 6 },
    ];

    let remainingElectrons = electrons;
    let orbitalNotation = '';

    for (const orbital of orbitals) {
      if (remainingElectrons <= 0) break;
      const electronsInOrbital = Math.min(remainingElectrons, orbital.capacity);
      orbitalNotation += `${orbital.level}${electronsInOrbital} `;
      remainingElectrons -= electronsInOrbital;
    }

    return `${orbitalNotation.trim()} (${charge > 0 ? `+${charge}` : charge})`;
  };

  const elements = [
    { "number": 1, "symbol": "H", "mass": 1, "name": "Hydrogen", "orbital": "1s1", "group": 1, "period": 1 },
    { "number": 2, "symbol": "He", "mass": 4, "name": "Helium", "orbital": "1s2", "group": 18, "period": 1 },
    { "number": 3, "symbol": "Li", "mass": 7, "name": "Lithium", "orbital": "[He] 2s1", "group": 1, "period": 2 },
    { "number": 4, "symbol": "Be", "mass": 9, "name": "Beryllium", "orbital": "[He] 2s2", "group": 2, "period": 2 },
    { "number": 5, "symbol": "B", "mass": 11, "name": "Boron", "orbital": "[He] 2s2 2p1", "group": 13, "period": 2 },
    { "number": 6, "symbol": "C", "mass": 12, "name": "Carbon", "orbital": "[He] 2s2 2p2", "group": 14, "period": 2 },
    { "number": 7, "symbol": "N", "mass": 14, "name": "Nitrogen", "orbital": "[He] 2s2 2p3", "group": 15, "period": 2 },
    { "number": 8, "symbol": "O", "mass": 16, "name": "Oxygen", "orbital": "[He] 2s2 2p4", "group": 16, "period": 2 },
    { "number": 9, "symbol": "F", "mass": 19, "name": "Fluorine", "orbital": "[He] 2s2 2p5", "group": 17, "period": 2 },
    { "number": 10, "symbol": "Ne", "mass": 20, "name": "Neon", "orbital": "[He] 2s2 2p6", "group": 18, "period": 2 },
    { "number": 11, "symbol": "Na", "mass": 23, "name": "Sodium", "orbital": "[Ne] 3s1", "group": 1, "period": 3 },
    { "number": 12, "symbol": "Mg", "mass": 24, "name": "Magnesium", "orbital": "[Ne] 3s2", "group": 2, "period": 3 },
    { "number": 13, "symbol": "Al", "mass": 27, "name": "Aluminum", "orbital": "[Ne] 3s2 3p1", "group": 13, "period": 3 },
    { "number": 14, "symbol": "Si", "mass": 28, "name": "Silicon", "orbital": "[Ne] 3s2 3p2", "group": 14, "period": 3 },
    { "number": 15, "symbol": "P", "mass": 31, "name": "Phosphorus", "orbital": "[Ne] 3s2 3p3", "group": 15, "period": 3 },
    { "number": 16, "symbol": "S", "mass": 32, "name": "Sulfur", "orbital": "[Ne] 3s2 3p4", "group": 16, "period": 3 },
    { "number": 17, "symbol": "Cl", "mass": 35, "name": "Chlorine", "orbital": "[Ne] 3s2 3p5", "group": 17, "period": 3 },
    { "number": 18, "symbol": "Ar", "mass": 40, "name": "Argon", "orbital": "[Ne] 3s2 3p6", "group": 18, "period": 3 },
    { "number": 19, "symbol": "K", "mass": 39, "name": "Potassium", "orbital": "[Ar] 4s1", "group": 1, "period": 4 },
    { "number": 20, "symbol": "Ca", "mass": 40, "name": "Calcium", "orbital": "[Ar] 4s2", "group": 2, "period": 4 },
    { "number": 21, "symbol": "Sc", "mass": 45, "name": "Scandium", "orbital": "[Ar] 3d1 4s2", "group": 3, "period": 4 },
    { "number": 22, "symbol": "Ti", "mass": 48, "name": "Titanium", "orbital": "[Ar] 3d2 4s2", "group": 4, "period": 4 },
    { "number": 23, "symbol": "V", "mass": 51, "name": "Vanadium", "orbital": "[Ar] 3d3 4s2", "group": 5, "period": 4 },
    { "number": 24, "symbol": "Cr", "mass": 52, "name": "Chromium", "orbital": "[Ar] 3d5 4s1", "group": 6, "period": 4 },
    { "number": 25, "symbol": "Mn", "mass": 55, "name": "Manganese", "orbital": "[Ar] 3d5 4s2", "group": 7, "period": 4 },
    { "number": 26, "symbol": "Fe", "mass": 56, "name": "Iron", "orbital": "[Ar] 3d6 4s2", "group": 8, "period": 4 },
    { "number": 27, "symbol": "Co", "mass": 59, "name": "Cobalt", "orbital": "[Ar] 3d7 4s2", "group": 9, "period": 4 },
    { "number": 28, "symbol": "Ni", "mass": 59, "name": "Nickel", "orbital": "[Ar] 3d8 4s2", "group": 10, "period": 4 },
    { "number": 29, "symbol": "Cu", "mass": 64, "name": "Copper", "orbital": "[Ar] 3d10 4s1", "group": 11, "period": 4 },
    { "number": 30, "symbol": "Zn", "mass": 65, "name": "Zinc", "orbital": "[Ar] 3d10 4s2", "group": 12, "period": 4 },
    { "number": 31, "symbol": "Ga", "mass": 70, "name": "Gallium", "orbital": "[Ar] 3d10 4s2 4p1", "group": 13, "period": 4 },
    { "number": 32, "symbol": "Ge", "mass": 73, "name": "Germanium", "orbital": "[Ar] 3d10 4s2 4p2", "group": 14, "period": 4 },
    { "number": 33, "symbol": "As", "mass": 75, "name": "Arsenic", "orbital": "[Ar] 3d10 4s2 4p3", "group": 15, "period": 4 },
    { "number": 34, "symbol": "Se", "mass": 79, "name": "Selenium", "orbital": "[Ar] 3d10 4s2 4p4", "group": 16, "period": 4 },
    { "number": 35, "symbol": "Br", "mass": 80, "name": "Bromine", "orbital": "[Ar] 3d10 4s2 4p5", "group": 17, "period": 4 },
    { "number": 36, "symbol": "Kr", "mass": 84, "name": "Krypton", "orbital": "[Ar] 3d10 4s2 4p6", "group": 18, "period": 4 },
    { "number": 37, "symbol": "Rb", "mass": 85, "name": "Rubidium", "orbital": "[Kr] 5s1", "group": 1, "period": 5 },
    { "number": 38, "symbol": "Sr", "mass": 88, "name": "Strontium", "orbital": "[Kr] 5s2", "group": 2, "period": 5 },
    { "number": 39, "symbol": "Y", "mass": 89, "name": "Yttrium", "orbital": "[Kr] 4d1 5s2", "group": 3, "period": 5 },
    { "number": 40, "symbol": "Zr", "mass": 91, "name": "Zirconium", "orbital": "[Kr] 4d2 5s2", "group": 4, "period": 5 },
    { "number": 41, "symbol": "Nb", "mass": 93, "name": "Niobium", "orbital": "[Kr] 4d4 5s1", "group": 5, "period": 5 },
    { "number": 42, "symbol": "Mo", "mass": 96, "name": "Molybdenum", "orbital": "[Kr] 4d5 5s1", "group": 6, "period": 5 },
    { "number": 43, "symbol": "Tc", "mass": 98, "name": "Technetium", "orbital": "[Kr] 4d5 5s2", "group": 7, "period": 5 },
    { "number": 44, "symbol": "Ru", "mass": 101, "name": "Ruthenium", "orbital": "[Kr] 4d7 5s1", "group": 8, "period": 5 },
    { "number": 45, "symbol": "Rh", "mass": 103, "name": "Rhodium", "orbital": "[Kr] 4d8 5s1", "group": 9, "period": 5 },
    { "number": 46, "symbol": "Pd", "mass": 106, "name": "Palladium", "orbital": "[Kr] 4d10", "group": 10, "period": 5 },
    { "number": 47, "symbol": "Ag", "mass": 108, "name": "Silver", "orbital": "[Kr] 4d10 5s1", "group": 11, "period": 5 },
    { "number": 48, "symbol": "Cd", "mass": 112, "name": "Cadmium", "orbital": "[Kr] 4d10 5s2", "group": 12, "period": 5 },
    { "number": 49, "symbol": "In", "mass": 115, "name": "Indium", "orbital": "[Kr] 4d10 5s2 5p1", "group": 13, "period": 5 },
    { "number": 50, "symbol": "Sn", "mass": 119, "name": "Tin", "orbital": "[Kr] 4d10 5s2 5p2", "group": 14, "period": 5 },
    { "number": 51, "symbol": "Sb", "mass": 122, "name": "Antimony", "orbital": "[Kr] 4d10 5s2 5p3", "group": 15, "period": 5 },
    { "number": 52, "symbol": "Te", "mass": 128, "name": "Tellurium", "orbital": "[Kr] 4d10 5s2 5p4", "group": 16, "period": 5 },
    { "number": 53, "symbol": "I", "mass": 127, "name": "Iodine", "orbital": "[Kr] 4d10 5s2 5p5", "group": 17, "period": 5 },
    { "number": 54, "symbol": "Xe", "mass": 131, "name": "Xenon", "orbital": "[Kr] 4d10 5s2 5p6", "group": 18, "period": 5 },
    { "number": 55, "symbol": "Cs", "mass": 133, "name": "Cesium", "orbital": "[Xe] 6s1", "group": 1, "period": 6 },
    { "number": 56, "symbol": "Ba", "mass": 137, "name": "Barium", "orbital": "[Xe] 6s2", "group": 2, "period": 6 },
    { "number": 57, "symbol": "La", "mass": 139, "name": "Lanthanum", "orbital": "[Xe] 5d1 6s2", "group": 3, "period": 6 },
    { "number": 58, "symbol": "Ce", "mass": 140, "name": "Cerium", "orbital": "[Xe] 4f1 5d1 6s2", "group": 3, "period": 6 },
    { "number": 59, "symbol": "Pr", "mass": 141, "name": "Praseodymium", "orbital": "[Xe] 4f3 6s2", "group": 3, "period": 6 },
    { "number": 60, "symbol": "Nd", "mass": 144, "name": "Neodymium", "orbital": "[Xe] 4f4 6s2", "group": 3, "period": 6 },
    { "number": 61, "symbol": "Pm", "mass": 145, "name": "Promethium", "orbital": "[Xe] 4f5 6s2", "group": 3, "period": 6 },
    { "number": 62, "symbol": "Sm", "mass": 150, "name": "Samarium", "orbital": "[Xe] 4f6 6s2", "group": 3, "period": 6 },
    { "number": 63, "symbol": "Eu", "mass": 152, "name": "Europium", "orbital": "[Xe] 4f7 6s2", "group": 3, "period": 6 },
    { "number": 64, "symbol": "Gd", "mass": 157, "name": "Gadolinium", "orbital": "[Xe] 4f7 5d1 6s2", "group": 3, "period": 6 },
    { "number": 65, "symbol": "Tb", "mass": 159, "name": "Terbium", "orbital": "[Xe] 4f9 6s2", "group": 3, "period": 6 },
    { "number": 66, "symbol": "Dy", "mass": 162, "name": "Dysprosium", "orbital": "[Xe] 4f10 6s2", "group": 3, "period": 6 },
    { "number": 67, "symbol": "Ho", "mass": 165, "name": "Holmium", "orbital": "[Xe] 4f11 6s2", "group": 3, "period": 6 },
    { "number": 68, "symbol": "Er", "mass": 167, "name": "Erbium", "orbital": "[Xe] 4f12 6s2", "group": 3, "period": 6 },
    { "number": 69, "symbol": "Tm", "mass": 169, "name": "Thulium", "orbital": "[Xe] 4f13 6s2", "group": 3, "period": 6 },
    { "number": 70, "symbol": "Yb", "mass": 173, "name": "Ytterbium", "orbital": "[Xe] 4f14 6s2", "group": 3, "period": 6 },
    { "number": 71, "symbol": "Lu", "mass": 175, "name": "Lutetium", "orbital": "[Xe] 4f14 5d1 6s2", "group": 3, "period": 6 },
    { "number": 72, "symbol": "Hf", "mass": 178, "name": "Hafnium", "orbital": "[Xe] 4f14 5d2 6s2", "group": 4, "period": 6 },
    { "number": 73, "symbol": "Ta", "mass": 181, "name": "Tantalum", "orbital": "[Xe] 4f14 5d3 6s2", "group": 5, "period": 6 },
    { "number": 74, "symbol": "W", "mass": 184, "name": "Tungsten", "orbital": "[Xe] 4f14 5d4 6s2", "group": 6, "period": 6 },
    { "number": 75, "symbol": "Re", "mass": 186, "name": "Rhenium", "orbital": "[Xe] 4f14 5d5 6s2", "group": 7, "period": 6 },
    { "number": 76, "symbol": "Os", "mass": 190, "name": "Osmium", "orbital": "[Xe] 4f14 5d6 6s2", "group": 8, "period": 6 },
    { "number": 77, "symbol": "Ir", "mass": 192, "name": "Iridium", "orbital": "[Xe] 4f14 5d7 6s2", "group": 9, "period": 6 },
    { "number": 78, "symbol": "Pt", "mass": 195, "name": "Platinum", "orbital": "[Xe] 4f14 5d9 6s1", "group": 10, "period": 6 },
    { "number": 79, "symbol": "Au", "mass": 197, "name": "Gold", "orbital": "[Xe] 4f14 5d10 6s1", "group": 11, "period": 6 },
    { "number": 80, "symbol": "Hg", "mass": 201, "name": "Mercury", "orbital": "[Xe] 4f14 5d10 6s2", "group": 12, "period": 6 },
    { "number": 81, "symbol": "Tl", "mass": 204, "name": "Thallium", "orbital": "[Xe] 4f14 5d10 6s2 6p1", "group": 13, "period": 6 },
    { "number": 82, "symbol": "Pb", "mass": 207, "name": "Lead", "orbital": "[Xe] 4f14 5d10 6s2 6p2", "group": 14, "period": 6 },
    { "number": 83, "symbol": "Bi", "mass": 209, "name": "Bismuth", "orbital": "[Xe] 4f14 5d10 6s2 6p3", "group": 15, "period": 6 },
    { "number": 84, "symbol": "Po", "mass": 209, "name": "Polonium", "orbital": "[Xe] 4f14 5d10 6s2 6p4", "group": 16, "period": 6 },
    { "number": 85, "symbol": "At", "mass": 210, "name": "Astatine", "orbital": "[Xe] 4f14 5d10 6s2 6p5", "group": 17, "period": 6 },
    { "number": 86, "symbol": "Rn", "mass": 222, "name": "Radon", "orbital": "[Xe] 4f14 5d10 6s2 6p6", "group": 18, "period": 6 },
    { "number": 87, "symbol": "Fr", "mass": 223, "name": "Francium", "orbital": "[Rn] 7s1", "group": 1, "period": 7 },
    { "number": 88, "symbol": "Ra", "mass": 226, "name": "Radium", "orbital": "[Rn] 7s2", "group": 2, "period": 7 },
    { "number": 89, "symbol": "Ac", "mass": 227, "name": "Actinium", "orbital": "[Rn] 6d1 7s2", "group": 3, "period": 7 },
    { "number": 90, "symbol": "Th", "mass": 232, "name": "Thorium", "orbital": "[Rn] 6d2 7s2", "group": 3, "period": 7 },
    { "number": 91, "symbol": "Pa", "mass": 231, "name": "Protactinium", "orbital": "[Rn] 5f2 6d1 7s2", "group": 3, "period": 7 },
    { "number": 92, "symbol": "U", "mass": 238, "name": "Uranium", "orbital": "[Rn] 5f3 6d1 7s2", "group": 3, "period": 7 },
    { "number": 93, "symbol": "Np", "mass": 237, "name": "Neptunium", "orbital": "[Rn] 5f4 6d1 7s2", "group": 3, "period": 7 },
    { "number": 94, "symbol": "Pu", "mass": 244, "name": "Plutonium", "orbital": "[Rn] 5f6 7s2", "group": 3, "period": 7 },
    { "number": 95, "symbol": "Am", "mass": 243, "name": "Americium", "orbital": "[Rn] 5f7 7s2", "group": 3, "period": 7 },
    { "number": 96, "symbol": "Cm", "mass": 247, "name": "Curium", "orbital": "[Rn] 5f7 6d1 7s2", "group": 3, "period": 7 },
    { "number": 97, "symbol": "Bk", "mass": 247, "name": "Berkelium", "orbital": "[Rn] 5f9 7s2", "group": 3, "period": 7 },
    { "number": 98, "symbol": "Cf", "mass": 251, "name": "Californium", "orbital": "[Rn] 5f10 7s2", "group": 3, "period": 7 },
    { "number": 99, "symbol": "Es", "mass": 252, "name": "Einsteinium", "orbital": "[Rn] 5f11 7s2", "group": 3, "period": 7 },
    { "number": 100, "symbol": "Fm", "mass": 257, "name": "Fermium", "orbital": "[Rn] 5f12 7s2", "group": 3, "period": 7 },
    { "number": 101, "symbol": "Md", "mass": 258, "name": "Mendelevium", "orbital": "[Rn] 5f13 7s2", "group": 3, "period": 7 },
    { "number": 102, "symbol": "No", "mass": 259, "name": "Nobelium", "orbital": "[Rn] 5f14 7s2", "group": 3, "period": 7 },
    { "number": 103, "symbol": "Lr", "mass": 262, "name": "Lawrencium", "orbital": "[Rn] 5f14 7s2 7p1", "group": 3, "period": 7 },
    { "number": 104, "symbol": "Rf", "mass": 267, "name": "Rutherfordium", "orbital": "[Rn] 5f14 6d2 7s2", "group": 4, "period": 7 },
    { "number": 105, "symbol": "Db", "mass": 270, "name": "Dubnium", "orbital": "[Rn] 5f14 6d3 7s2", "group": 5, "period": 7 },
    { "number": 106, "symbol": "Sg", "mass": 271, "name": "Seaborgium", "orbital": "[Rn] 5f14 6d4 7s2", "group": 6, "period": 7 },
    { "number": 107, "symbol": "Bh", "mass": 270, "name": "Bohrium", "orbital": "[Rn] 5f14 6d5 7s2", "group": 7, "period": 7 },
    { "number": 108, "symbol": "Hs", "mass": 277, "name": "Hassium", "orbital": "[Rn] 5f14 6d6 7s2", "group": 8, "period": 7 },
    { "number": 109, "symbol": "Mt", "mass": 276, "name": "Meitnerium", "orbital": "[Rn] 5f14 6d7 7s2", "group": 9, "period": 7 },
    { "number": 110, "symbol": "Ds", "mass": 281, "name": "Darmstadtium", "orbital": "[Rn] 5f14 6d8 7s2", "group": 10, "period": 7 },
    { "number": 111, "symbol": "Rg", "mass": 282, "name": "Roentgenium", "orbital": "[Rn] 5f14 6d10 7s1", "group": 11, "period": 7 },
    { "number": 112, "symbol": "Cn", "mass": 285, "name": "Copernicium", "orbital": "[Rn] 5f14 6d10 7s2", "group": 12, "period": 7 },
    { "number": 113, "symbol": "Nh", "mass": 284, "name": "Nihonium", "orbital": "[Rn] 5f14 6d10 7s2 7p1", "group": 13, "period": 7 },
    { "number": 114, "symbol": "Fl", "mass": 289, "name": "Flerovium", "orbital": "[Rn] 5f14 6d10 7s2 7p2", "group": 14, "period": 7 },
    { "number": 115, "symbol": "Mc", "mass": 290, "name": "Moscovium", "orbital": "[Rn] 5f14 6d10 7s2 7p3", "group": 15, "period": 7 },
    { "number": 116, "symbol": "Lv", "mass": 293, "name": "Livermorium", "orbital": "[Rn] 5f14 6d10 7s2 7p4", "group": 16, "period": 7 },
    { "number": 117, "symbol": "Ts", "mass": 294, "name": "Tennessine", "orbital": "[Rn] 5f14 6d10 7s2 7p5", "group": 17, "period": 7 },
    { "number": 118, "symbol": "Og", "mass": 294, "name": "Oganesson", "orbital": "[Rn] 5f14 6d10 7s2 7p6", "group": 18, "period": 7 }
  ];

  return (
    <div>
      <center>
        <div className="text-container">
          <h2>Welcome to the BohrViewer</h2>
          <p>
            This interactive tool allows you to explore Bohr models of various elements. The application dynamically renders detailed atomic models based on the atomic number, mass, and ionization state.
          </p>
          <p>
            <strong>How to use:</strong> 
            <p>Click on an element in the periodic table to view its Bohr model.</p>
            <p>Use your mouse to rotate the model for a complete view.</p>
            <p>Zoom in and out to examine details.</p>
          </p>
        </div>
      </center>
      <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f0f0f5' }}>
        <div style={{ width: '60%', position: 'relative', margin: '20px', border: '2px solid #ccc', borderRadius: '10px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
          <div id="three-container" style={{ width: '100%', height: '100%', backgroundColor: 'white' }}></div>
          <div style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            padding: '15px',
            borderRadius: '8px',
            zIndex: 2,
            fontFamily: 'Arial, sans-serif'
          }}>
            <div style={{ fontSize: '18px', fontWeight: 'bold' }}>{atomicName}</div>
            <div style={{ fontSize: '18px', fontWeight: 'bold' }}>
              {atomicSymbol}
              {atomicNumber - electronCount !== 0 && <sup>{atomicNumber - electronCount > 0 ? `+${atomicNumber - electronCount}` : `${atomicNumber - electronCount}`}</sup>}
            </div>
            <div style={{ marginTop: '5px' }}>Atomic Number: {atomicNumber}</div>
            <div>Atomic Mass: {atomicMass}</div>
            <div>Orbital: {atomicOrbital}</div>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
              <button 
                onClick={handleIonizeClick} 
                style={{ padding: '10px', backgroundColor: '#333', color: '#fff', borderRadius: '5px' }}>
                Remove Electron
              </button>
              <button 
                onClick={handleAddElectronClick} 
                style={{ padding: '10px', backgroundColor: '#333', color: '#fff', borderRadius: '5px' }}>
                Add Electron
              </button>
            </div>
          </div>
        </div>
        <div className="periodic-table" style={{ width: '100%', padding: '20px', backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h3 style={{ fontFamily: 'Arial, sans-serif', textAlign: 'center' }}>Periodic Table</h3>
          <div className="periodic-table" style={{ display: 'grid', gridTemplateColumns: 'repeat(18, 1fr)', gap: '5px', justifyContent: 'center' }}>
            {elements.map((element) => (
              <div
                key={element.number}
                onClick={() => handleElementClick(element.number, element.mass, element.symbol, element.name, element.orbital)}
                style={{
                  gridColumn: `${element.group}`,
                  gridRow: `${element.period}`,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid #ddd',
                  cursor: 'pointer',
                  backgroundColor: '#fafafa',
                  borderRadius: '4px',
                  transition: 'all 0.2s',
                  fontFamily: 'Arial, sans-serif',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  padding: '10px',
                }}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f0f0f5'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#fafafa'}
              >
                <div style={{ textAlign: 'center' }}>
                  <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{element.symbol}</div>
                  <div style={{ fontSize: '10px' }}>{element.number}</div>
                  <div style={{ fontSize: '8px' }}>{element.mass}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeComponent;
