// src/LabThreeComponent.js
import React, { useEffect } from 'react';
import { initThreeJS } from './lab_threesetup';

const LabThreeComponent = () => {
  useEffect(() => {
    console.log('LabThreeComponent mounted');
    // Initialize Three.js when the component mounts
    initThreeJS('three-container');

    // Cleanup on component unmount
    return () => {
      console.log('LabThreeComponent unmounted');
      const container = document.getElementById('three-container');
      if (container) {
        container.innerHTML = ''; // Clear Three.js renderer on unmount
      }
    };
  }, []);

  return (
    <div id="three-container" style={{ width: '100%', height: '100vh', backgroundColor: '#DCDCDC' }}></div>
  );
};

export default LabThreeComponent;
