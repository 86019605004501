import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

const SocialMedia = () => {
    return (
        <div className="social-media">
            <a href="https://www.facebook.com/profile.php?id=61554477032068" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} size="1x" />
            </a>
            <a href="https://www.twitter.com/Andrewslearning" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} size="1x" />
            </a>
            <a href="https://www.youtube.com/@andrewslearningcenter" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} size="1x" />
            </a>
            <a href="https://www.instagram.com/andrewslearning" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="1x" />
            </a>
            
        </div>
    );
};

export default SocialMedia;
