import React, { useState } from 'react';

const UnitConversion = () => {
    const [referenceUnit, setReferenceUnit] = useState('g');
    const [comparisonUnit, setComparisonUnit] = useState('mg');
    const [referenceCount, setReferenceCount] = useState(1);
    const [frameCount, setFrameCount] = useState(1);

    const dimensions = {
        'g': 200,
        'mg': 20,
        'ug': 2
    };

    const unitsInAGram = {
        'g': 1,
        'mg': 1000,
        'ug': 1000000
    };

    const maxSquaresToShow = 50;
    const relativeRatio = unitsInAGram[comparisonUnit] / unitsInAGram[referenceUnit] * referenceCount;
    const squaresCount = Math.min(relativeRatio, maxSquaresToShow * frameCount);

    let squares = Array.from({length: squaresCount}, (_, i) => (
        <div 
            key={i} 
            className="square" 
            style={{
                width: `${dimensions[comparisonUnit]}px`,
                height: `${dimensions[comparisonUnit]}px`,
                backgroundColor: 'blue'
            }} 
        />
    ));

    return (
        <div className="app-container">
            <h1>Unit Conversion with Shapes</h1>

            <div className="controls">
                <label>Reference Unit:</label>
                <select value={referenceUnit} onChange={e => setReferenceUnit(e.target.value)}>
                    <option value="g">gram</option>
                    <option value="mg">milligram</option>
                    <option value="ug">microgram</option>
                </select>

                <label>Comparison Unit:</label>
                <select value={comparisonUnit} onChange={e => setComparisonUnit(e.target.value)}>
                    <option value="g">gram</option>
                    <option value="mg">milligram</option>
                    <option value="ug">microgram</option>
                </select>

                <label>Number of Reference Squares:</label>
                <input type="number" value={referenceCount} onChange={e => setReferenceCount(+e.target.value)} min="1" max="10" />

                <label>Number of Frame Squares:</label>
                <input type="number" value={frameCount} onChange={e => setFrameCount(+e.target.value)} min="1" max="10" />
            </div>

            <div className="shapes-container" style={{width: `${dimensions[referenceUnit] * frameCount}px`, height: `${dimensions[referenceUnit] * frameCount}px`}}>
                {squares}
            </div>

            <style jsx>{`
                .app-container {
                    font-family: Arial, sans-serif;
                    padding: 20px;
                    width: 90%;
                    max-width: 800px;
                    margin: 0 auto;
                    text-align: center;
                    overflow-y: auto;
                }

                .controls {
                    margin: 20px 0;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }

                label {
                    font-weight: bold;
                }

                .shapes-container {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 2px;
                    justify-content: center;
                    align-content: flex-start;
                    border: 1px solid #ccc;
                    position: relative;
                }

                .square {
                    margin: 1px;
                    background-color: #0077c2;
                }
            `}</style>
        </div>
    );
};

export default UnitConversion;

