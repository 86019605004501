import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import * as math from 'mathjs';
import '../App.css';
import MathKeyboard from './MathKeyboard'; // Import the keyboard

const FunctionGraph = () => {
    const [func, setFunc] = useState('x^2');
    const [yValues, setYValues] = useState([]);
    const [range, setRange] = useState({ xStart: -10, xEnd: 10 });

    useEffect(() => {
        try {
            const xValues = Array.from({ length: 500 }, (_, i) => range.xStart + i * (range.xEnd - range.xStart) / 500);
            const newFunc = math.compile(func);
            const newYValues = xValues.map(x => newFunc.evaluate({ x }));
            setYValues(newYValues);
        } catch (e) {
            console.error('Invalid function');
        }
    }, [func, range]);

    const handleZoomIn = () => setRange({ xStart: range.xStart / 2, xEnd: range.xEnd / 2 });
    const handleZoomOut = () => setRange({ xStart: range.xStart * 2, xEnd: range.xEnd * 2 });
    const handleReset = () => setRange({ xStart: -10, xEnd: 10 });

    const handleKeyPress = (key) => {
        if (key === '<<') {
            setFunc(func.slice(0, -1)); // Backspace
        } else {
            setFunc(func + key);
        }
    };

    const xValues = Array.from({ length: 500 }, (_, i) => range.xStart + i * (range.xEnd - range.xStart) / 500);

    return (
        <div className="app-container">
            <div className="graphContainer">
                <h1 className="app-title">Visualize Math Functions</h1>
                <p className="app-description">
                    This tool helps you visualize mathematical functions. You can enter any function in the box below, and the graph of the function will be displayed. 
                    The default function is y = x^2.
                </p>
                <div className="function-input">
                    <label className="function-label">Enter a function:</label>
                    <input className="function-textbox" type="text" value={func} onChange={(e) => setFunc(e.target.value)} />
                </div>
                <Plot
                    data={[
                        {
                            x: xValues,
                            y: yValues,
                            type: 'scatter',
                            mode: 'lines',
                            line: { color: 'blue', width: 2 },
                        }
                    ]}
                    layout={{
                        width: '100%',
                        height: '100%',
                        title: `Graph of y = ${func}`,
                        xaxis: {
                            title: 'x',
                            showgrid: true,
                            zeroline: true,
                            gridcolor: 'rgba(200, 200, 200, 0.3)',
                        },
                        yaxis: {
                            title: 'y',
                            showline: true,
                            zeroline: true,
                            gridcolor: 'rgba(200, 200, 200, 0.3)',
                        },
                        margin: {
                            t: 30, r: 30, b: 50, l: 50
                        },
                    }}
                    useResizeHandler={true}
                    style={{ width: "100%", height: "100%" }}
                />
                <div className="graph-buttons">
                    <button className="graph-button" onClick={handleZoomIn}>+</button>
                    <button className="graph-button" onClick={handleReset}>⟳</button>
                    <button className="graph-button" onClick={handleZoomOut}>-</button>
                </div>
            </div>
            <div className="keyboard-container">
                <MathKeyboard onKeyPress={handleKeyPress} />
            </div>
        </div>
    );
}

export default FunctionGraph;
