import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Videolist.css'; // Ensure you have appropriate styles

const VideoList = () => {
  const [lectures, setLectures] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetch('https://api.andrewslearning.com/api/all_lectures')
      .then(response => {
        if (!response.ok) {
          throw new Error('HTTP error ' + response.status);
        }
        return response.json();
      })
      .then(data => setLectures(data))
      .catch(err => {
        console.error('Error retrieving lectures:', err);
        setError(err.toString());
      });
  }, []);

  const placeholderImage = "https://via.placeholder.com/150";
  const filteredLectures = lectures.filter(lecture =>
    lecture.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <h1 className="video-list-title">Lecture List</h1>
      <input
        type="text"
        placeholder="Search lectures..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-bar"
      />
      {error ? (
        <p>Error loading lectures: {error}</p>
      ) : (
        <div className="videos-grid">
          {filteredLectures.map((lecture) => (
            <Link key={lecture.id} to={`/lectures/${lecture.id}`} className="video-card-link">
              <div className="video-card">
                <img src={lecture.videoimage || placeholderImage} alt={lecture.title} className="video-image" />
                <div className="video-content">
                  <h2 className="video-title">{lecture.title}</h2>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  );
};

export default VideoList;
