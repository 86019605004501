// src/MatrixMultiplication.js
import React, { useState } from 'react';
import './MatrixMultiplication.css';

const MatrixMultiplication = () => {
  const [matrixA, setMatrixA] = useState([
    [1, 2],
    [3, 4],
  ]);
  const [matrixB, setMatrixB] = useState([
    [5, 6],
    [7, 8],
  ]);
  const [result, setResult] = useState(null);
  const [animationStep, setAnimationStep] = useState(0);

  const handleMatrixChange = (e, setMatrix) => {
    const { row, col } = e.target.dataset;
    const newValue = parseInt(e.target.value, 10);
    setMatrix((prevMatrix) => {
      const newMatrix = [...prevMatrix];
      newMatrix[row][col] = newValue;
      return newMatrix;
    });
  };

  const updateMatrix = (setMatrix) => {
    const rows = parseInt(prompt('Enter number of rows:'), 10);
    const cols = parseInt(prompt('Enter number of columns:'), 10);
    const newMatrix = Array.from({ length: rows }, () => Array(cols).fill(0));
    setMatrix(newMatrix);
  };

  const multiplyMatrices = () => {
    setAnimationStep(1);

    setTimeout(() => {
      setAnimationStep(2);

      setTimeout(() => {
        const rowsA = matrixA.length;
        const colsA = matrixA[0].length;
        const rowsB = matrixB.length;
        const colsB = matrixB[0].length;
        if (colsA !== rowsB) {
          alert('Matrices cannot be multiplied');
          return;
        }

        const result = Array.from({ length: rowsA }, () => Array(colsB).fill(0));
        for (let i = 0; i < rowsA; i++) {
          for (let j = 0; j < colsB; j++) {
            for (let k = 0; k < colsA; k++) {
              result[i][j] += matrixA[i][k] * matrixB[k][j];
            }
          }
        }
        setResult(result);
        setAnimationStep(3);
      }, 1000);
    }, 1000);
  };

  return (
    <div className="matrix-multiplication">
      <h1>Matrix Multiplication Visualizer</h1>
      <div className="matrix-container">
        <div>
          <h2>Matrix A</h2>
          <div className="matrix">
            {matrixA.map((row, rowIndex) => (
              <div key={rowIndex}>
                {row.map((value, colIndex) => (
                  <input
                    type="number"
                    value={value}
                    data-row={rowIndex}
                    data-col={colIndex}
                    key={`${rowIndex}-${colIndex}`}
                    onChange={(e) => handleMatrixChange(e, setMatrixA)}
                  />
                ))}
              </div>
            ))}
          </div>
          <button onClick={() => updateMatrix(setMatrixA)}>Update Matrix A</button>
        </div>
        <div>
          <h2>Matrix B</h2>
          <div
            className={`matrix ${animationStep > 0 ? 'flipped' : ''} ${
              animationStep > 1 ? 'align' : ''
            }`}
            id="matrixB"
          >
            {matrixB.map((row, rowIndex) => (
              <div key={rowIndex}>
                {row.map((value, colIndex) => (
                  <input
                    type="number"
                    value={value}
                    data-row={rowIndex}
                    data-col={colIndex}
                    key={`${rowIndex}-${colIndex}`}
                    onChange={(e) => handleMatrixChange(e, setMatrixB)}
                  />
                ))}
              </div>
            ))}
          </div>
          <button onClick={() => updateMatrix(setMatrixB)}>Update Matrix B</button>
        </div>
      </div>
      
      <button onClick={multiplyMatrices}>Multiply Matrices</button>
      <br/>
      <br/>
      <br/>
      <br/>
      {result && (
        <div>
          <h2>Result</h2>
          <div className="matrix">
            {result.map((row, rowIndex) => (
              <div key={rowIndex}>
                {row.map((value, colIndex) => (
                  <span className="result-cell" key={`${rowIndex}-${colIndex}`}>
                    {value}
                  </span>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MatrixMultiplication;
