import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import api from './api';
import '../Coursedetails.css';

function CourseDetailPage() {
  const { courseId } = useParams();
  const navigate = useNavigate();

  const [courseDetails, setCourseDetails] = useState(null);
  const [assessments, setAssessments] = useState([]);
  const [lectures, setLectures] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(0);

  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const access_token = userInfo ? userInfo.access : null;

  useEffect(() => {
    const fetchData = async () => {
      const config = access_token ? {
        headers: { Authorization: `Bearer ${access_token}` },
      } : {};

      const response = await api.get(`/api/courses/${courseId}`, config);
      setCourseDetails(response.data);

      const assessmentsResponse = await api.get(`/api/courses/${courseId}/assessments`, config);
      setAssessments(assessmentsResponse.data);

      const lecturesResponse = await api.get(`/api/courses/${courseId}/lectures`, config);
      setLectures(lecturesResponse.data);
    };
    fetchData();
  }, [courseId, access_token]);

  async function startAssessment(assessment) {
    if (!access_token) {
      console.log('Must be logged in to start assessments');
      return;
    }

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    const response = await api.post(`/api/start_assessment/${assessment.id}/`, {}, config);

    if (response.status === 200) {
      if (assessment.CAT) {
        navigate(`/catassessments/${assessment.id}`, { state: { assessmentResponseId: response.data.assessment_response_id } });
      } else {
        navigate(`/assessments/${assessment.id}`, { state: { assessmentResponseId: response.data.assessment_response_id } });
      }
    }
  }

  function getEmbedUrl(url) {
    let videoId = url.split('v=')[1];
    const ampersandPosition = videoId.indexOf('&');
    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }
    return `https://www.youtube.com/embed/${videoId}`;
  }

  if (!courseDetails || !lectures) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-8 col-md-12">
          <center>
            <h1>{courseDetails.title}</h1>
            <p>{courseDetails.description}</p>
          </center>

          {lectures.map((lecture, index) => (
  <div
    key={index}
    className="video-frame"
    style={{ display: currentVideo === index ? 'block' : 'none' }}
  >
    <h3 className="text-center">{lecture.title}</h3>
    {lecture.video ? (
      // If a video URL is present, embed the YouTube video
      <div className="video-container">
        <center>
          <iframe
            src={getEmbedUrl(lecture.video)}
            title="Embedded Video"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            width="800"
            height="450"
          ></iframe>
        </center>
      </div>
    ) : access_token ? (
      // If a video URL isn’t present but the user is signed in, show an image
      <div className="image-container">
        <center>
          <img 
            src="https://andrewslearningimage-bucket.s3.amazonaws.com/media/Coming_soon+copy.png" 
            alt="Content Coming Soon"
            width="800"
            height="450"
          />
        </center>
      </div>
    ) : (
      // If a video URL isn’t present and the user isn’t signed in, show a sign-in prompt
      <div className="sign-in-prompt">
        <h2>Sign in for video lectures, assessments, and notes</h2>
        <button onClick={() => navigate('/login')}>Sign In</button>
      </div>
    )}
  </div>
))}

        
          <div className="assessments-container">
            <h2>Assessments</h2>
            <ul className="assessments-list">
              {assessments.map(assessment => (
                <li key={assessment.id} className="assessment-item">
                  <span>{assessment.name}</span>
                  <button className="start-assessment-button" onClick={() => startAssessment(assessment)}>Start</button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-lg-4 col-md-12">
          <br/>
          <br/>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title text-center">Lectures</h3>
            </div>
            <div className="card-body playlist-container">
              <div className="list-group">
                {lectures.map((lecture, index) => (
                  <button
                    key={index}
                    className="list-group-item list-group-item-action"
                    onClick={() => setCurrentVideo(index)}
                  >
                    {lecture.title}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
    </div>
  );
}

export default CourseDetailPage;
