import React, { useState } from 'react';
import mathsteps from 'mathsteps';

const AlgebraLabSteps = () => {
    const [equation, setEquation] = useState('');
    const [solutionSteps, setSolutionSteps] = useState([]);

    const handleInputChange = (event) => {
        setEquation(event.target.value);
    }

    const handleSolve = () => {
        try {
            const steps = mathsteps.solveEquation(equation);
            setSolutionSteps(steps);
        } catch (error) {
            console.error(error);
            setSolutionSteps([{changeType: 'error', error: 'An error occurred while solving the equation'}]);
        }
    }

    return (
        <div>
            <input type="text" value={equation} onChange={handleInputChange} />
            <button onClick={handleSolve}>Solve</button>
            {solutionSteps.map((step, index) => (
                <div key={index}>
                    <p>{`Step ${index + 1}: ${step.changeType}`}</p>
                    <p>{`Before change: ${step.oldNode ? step.oldNode.toString() : ''}`}</p>
                    <p>{`After change: ${step.newNode ? step.newNode.toString() : ''}`}</p>
                    <p>{`Error: ${step.error ? step.error : ''}`}</p>
                </div>
            ))}
        </div>
    );
}

export default AlgebraLabSteps;
